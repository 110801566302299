import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsSiteService {
  private KEY_PAGE_SIZE = 'glx_ps';
  private DEFAULT_PAGE_SIZE = 10;
  public PAGE_SIZE_VARIANT = [10, 25, 50];

  private KEY_FIELDS_TABLE_ISSUES = 'glx_fti';
  private DEFAULT_FIELDS_TABLE_ISSUES = ['number', 'title', 'status', 'type'];

  private logoutToClearKeys = [];

  constructor() { }

  getPageSize(): number {
    const value = localStorage.getItem(this.KEY_PAGE_SIZE);
    return value ? parseInt(value, 10) : this.DEFAULT_PAGE_SIZE;
  }

  setPageSize(size: number): void {
    localStorage.setItem(this.KEY_PAGE_SIZE, size.toString());
  }

  getFieldsTableIssues(): string[] {
    const value = localStorage.getItem(this.KEY_FIELDS_TABLE_ISSUES);
    return value ? JSON.parse(value) : this.DEFAULT_FIELDS_TABLE_ISSUES;
  }

  setFieldsTableIssues(fti: string[]): void {
    localStorage.setItem(this.KEY_FIELDS_TABLE_ISSUES, JSON.stringify(fti));
  }

  reset(): void{
    this.logoutToClearKeys.forEach(key => localStorage.removeItem(key));
  }
}
