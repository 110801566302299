import { Injectable } from '@angular/core';
import {GHttpService} from '../galaxy/ghttp.service';
import {Observable, of} from 'rxjs';
import {
  GQLAllLevels,
  GQLLevelAnalysisNamespace,
  GQLLevelAnalysisOverallBimModelHealth,
  Level,
  LevelAnalysisNamespace
} from './level-analysis';
import {ObjectIdName} from '../galaxy/g';
import {map, tap} from 'rxjs/operators';
import {HealthPoint} from '../dashboard/dashboard';

@Injectable({
  providedIn: 'root'
})
export class LevelAnalysisService {
  private levels: Level[] = [];

  constructor(
    private http: GHttpService
  ) { }

  getData(idLevel: string): Observable<LevelAnalysisNamespace> {
    return this.http.graphql(GQLLevelAnalysisNamespace, ['user', 'levelAnalysis'], {id: idLevel});
  }

  getAllLevels(): Observable<Level[]> {
    if (this.levels.length > 0) {
      return of(this.levels);
    }
    return this.http.graphqlGetFull<ObjectIdName>(GQLAllLevels, {}, ['user', 'issueLog', 'zones'])
      .pipe(
        map(data => {
          return data.map(item => {
            return {
              ...item,
              slug: item.name.toLowerCase().replace(/ /gi, '-')
            };
          });
        }),
        tap(data => this.levels = data)
      );
  }
  getBimModelHealth(idLevel: string, period: string, startDate: string, endDate: string): Observable<HealthPoint[]> {
    return this.http.graphql(GQLLevelAnalysisOverallBimModelHealth, ['user', 'levelAnalysis', 'charts', 'bimModelHealth'], {
      id: idLevel, period, startDate, endDate
    });
  }
}
