import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AuthComponent} from './auth/auth.component';
import {SignUpComponent} from './auth/sign-up/sign-up.component';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {ConfirmSignUpComponent} from './auth/confirm-sign-up/confirm-sign-up.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {ConfirmResetPasswordComponent} from './auth/confirm-reset-password/confirm-reset-password.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppNotfoundComponent} from './base/notfound/app.notfound.component';
import {RequestReceivedComponent} from './auth/request-received/request-received.component';
import {BaseComponent} from './base/base.component';
import {AccountSettingsComponent} from './account-settings/account-settings.component';
import {ChangePasswordComponent} from './account-settings/change-password/change-password.component';
import {AdminGuard} from './admin/admin.guard';
import {IssuesComponent} from './issues/issues.component';
import {PdfPagePlanComponent} from './pdfPage/plan/plan.component';
import {PdfPageScheduleComponent} from './pdfPage/schedule/schedule.component';
import {ContactsComponent} from './contacts/contacts.component';
import {AnnouncementsComponent} from './announcements/announcements.component';
import {LevelAnalysisComponent} from './level-analysis/level-analysis.component';
import {ModelViewComponent} from './model-view/model-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent
      },
      {
        path: 'account-settings/change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
        canLoad: [AdminGuard]
      },
      {
        path: 'issue-log',
        component: IssuesComponent
      },
      {
        path: 'bim-execution-plan',
        component: PdfPagePlanComponent
      },
      {
        path: 'bim-execution-plan/:slug',
        component: PdfPagePlanComponent
      },
      {
        path: 'bim-coordination-schedule',
        component: PdfPageScheduleComponent
      },
      {
        path: 'bim-coordination-schedule/:slug',
        component: PdfPageScheduleComponent
      },
      {
        path: 'contacts',
        component: ContactsComponent
      },
      {
        path: 'announcements',
        component: AnnouncementsComponent
      },
      {
        path: 'analysis',
        component: LevelAnalysisComponent
      },
      {
        path: 'analysis/:slug',
        component: LevelAnalysisComponent
      },
      {
        path: 'model-view',
        component: ModelViewComponent
      },
      {
        path: 'model-view/:slug',
        component: ModelViewComponent
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'confirm-sign-up',
        component: ConfirmSignUpComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'confirm-reset-password',
        component: ConfirmResetPasswordComponent
      },
      {
        path: 'request-received',
        component: RequestReceivedComponent
      }
    ],
  },
  {path: '**', component: AppNotfoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
