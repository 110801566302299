import { Injectable } from '@angular/core';
import {GHttpService} from '../galaxy/ghttp.service';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {
  Announcement,
  GQLAllAnnouncements,
  GQLMarkAnnouncementsAsRead,
  GQLNumUnreadAnnouncements
} from './announcements';
import {switchMap} from 'rxjs/operators';
import {GQLSuccessResult} from '../galaxy/g';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  public numUnreadAnnouncements = new BehaviorSubject<number>(0);

  constructor(
    private http: GHttpService
  ) {
    this.intervalGetNumUnreadAnnouncements().subscribe(data => {
      this.numUnreadAnnouncements.next(data);
    });
  }

  getAllAnnouncements(): Observable<Announcement[]> {
    return this.http.graphqlGetFull(GQLAllAnnouncements, {}, ['user', 'announcements', 'list']);
  }

  getNumUnreadAnnouncements(): Observable<number> {
    return this.http.graphql(GQLNumUnreadAnnouncements, ['user', 'announcements', 'numberOfUnread']);
  }

  markAnnouncementsAsRead(ids: string[]): Observable<any> {
    return this.http.graphql(GQLMarkAnnouncementsAsRead(ids), ['user']);
  }

  private intervalGetNumUnreadAnnouncements(): Observable<number> {
    return timer(0, 60 * 10 * 1000).pipe(
      switchMap(() => {
        return this.getNumUnreadAnnouncements();
      })
    );
  }

  updateNumUnreadAnnouncements(): void {
    this.getNumUnreadAnnouncements().subscribe(data => {
      this.numUnreadAnnouncements.next(data);
    });
  }
}
