import {Component, Input } from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataCategoryChart} from '../charts';

@Component({
  selector: 'app-lines-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['./lines-chart.component.scss']
})
export class LinesChartComponent  extends AmChartsComponent {
  @Input() data: DataCategoryChart|null = null;


  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data) {
      this.empty();
      return;
    }

    am4core.useTheme(am4themesAnimated);

    const chart = am4core.create(this.idChart, am4charts.XYChart);

    chart.data = this.data.items.map(item => {
      return {
        ...item,
        ...item.values
      };
    });

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.renderer.minGridDistance = 100;
    dateAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    dateAxis.renderer.labels.template.fontSize = this.fontSize;
    dateAxis.baseInterval = {
      timeUnit: 'hour',
      count: 1
    };
    dateAxis.cursorTooltipEnabled = false;


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    valueAxis.renderer.labels.template.fontSize = this.fontSize;

    // grid colors
    chart.plotContainer.background.strokeWidth = 0;
    dateAxis.renderer.grid.template.stroke = am4core.color(this.colorLine);
    dateAxis.renderer.grid.template.strokeWidth = 1;
    dateAxis.renderer.grid.template.strokeDasharray = '3,3';
    dateAxis.renderer.grid.template.strokeOpacity = .8;
    valueAxis.renderer.grid.template.stroke = am4core.color(this.colorLine);
    valueAxis.renderer.grid.template.strokeWidth = 1;
    valueAxis.renderer.grid.template.strokeDasharray = '3,3';
    valueAxis.renderer.grid.template.strokeOpacity = .8;
    valueAxis.min = 0;


    this.data.legend.forEach(({name, color}, index) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = index.toString();
      series.dataFields.dateX = 'category';
      series.fillOpacity = 0.3;
      series.tooltipText = name + ' ({' + index.toString() + '}){tooltip}';
      if (series.tooltip) {
        series.tooltip.pointerOrientation = 'vertical';
      }
      series.fill = am4core.color(color);
      series.stroke = am4core.color(color);
      series.strokeWidth = 3;
      series.name = name;

      const bullet = series.bullets.push(new am4charts.CircleBullet());
    });

    // cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = chart.series.values;
    chart.cursor.xAxis = dateAxis;
    // // cursor lineX
    // chart.cursor.lineX.stroke = am4core.color(this.textColor);
    // chart.cursor.lineX.strokeWidth = 1;
    // chart.cursor.lineX.strokeOpacity = 0.5;
    // chart.cursor.lineX.strokeDasharray = '1,3';
    // // cursor lineY
    // chart.cursor.lineY.stroke = am4core.color(this.textColor);
    // chart.cursor.lineY.strokeWidth = 1;
    // chart.cursor.lineY.strokeOpacity = 0.5;
    // chart.cursor.lineY.strokeDasharray = '1,3';
    //
    chart.cursor.maxTooltipDistance = 20;

    // legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color(this.textColor);
    chart.legend.position = 'bottom';
    chart.legend.fontSize = this.fontSize;
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    this.chart = chart;
  }
}
