import { Injectable } from '@angular/core';
import {CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean|UrlTree> {
    return this.auth.isAdmin().pipe(
      switchMap(isAdmin => {
        if (isAdmin) {
          return of(true);
        }
        else {
          return this.auth.isAuthenticated().pipe(
            map(isAuth => {
              if (!isAuth) {
                this.auth.redirectUrl = '/' + segments.map(item => item.path).join('/');
              }
              return this.router.parseUrl(isAuth ? '/dashboard' : '/sign-in');
            })
          );
        }
      })
    );
  }
}

