<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h2 class="p-mb-6">{{title}}</h2>
  <p>
    Enter the email you used to create your account. <br/>
    We'll send you an email with instructions.
  </p>
  <div class="p-fluid">
    <div class="p-field">
      <label>Email Address</label>
      <input pInputText formControlName="email" autocomplete="email"/>
    </div>
  </div>
  <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
  <button class="p-mt-3" pButton label="Reset Password" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
</form>
