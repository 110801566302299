import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-request-received',
  templateUrl: './request-received.component.html',
  styleUrls: ['./request-received.component.scss']
})
export class RequestReceivedComponent implements OnInit {
  public title = 'Request Received';
  constructor(private titleService: Title) {
    this.titleService.setTitle(this.title + ' | ' + environment.nameSite);
  }

  ngOnInit(): void {
  }

}
