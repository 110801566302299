<p-card>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <div class="p-field">
        <label>Old Password</label>
        <p-password [toggleMask]="true" [feedback]="false" formControlName="oldPassword" required autocomplete="current-password"></p-password>
      </div>
      <div class="p-field">
        <label>New Password</label>
        <p-password [toggleMask]="true" formControlName="newPassword" required autocomplete="new-password"></p-password>
      </div>
    </div>
    <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
    <button class="p-mt-3" pButton label="Change Password" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
  </form>
</p-card>
