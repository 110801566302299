<div *ngIf="weather" class="w">
  <div class="p-d-flex">
    <div>
      <div class="tempF">{{weather.temperature}}&deg;F</div>
      <div> {{weather.cloudiness}}% Clouds</div>
    </div>
    <div class="p-ml-auto">
      <div class="wi" [innerHTML]="weather.icon"></div>
    </div>
  </div>
  <div class="city">{{weather.place}}</div>
</div>

