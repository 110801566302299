import {AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  template: ''
})
export class AmChartsComponent implements AfterViewInit, OnDestroy {
  protected chart: any;
  protected fontSize = 12;
  protected textColor = '#495057';
  protected color = '#492764';
  protected colorLine = '#ccc';
  public isEmpty = false;

  @Input() idChart = 'none';
  @Input() data!: any;
  @Input() height = 300;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {}

  // Run the function only in the browser
  browserOnly(f: () => void): void {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {
    // Chart code goes in here
    this.browserOnly(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated'),
        import('@amcharts/amcharts4/themes/dataviz'),
        import('@amcharts/amcharts4/plugins/forceDirected')
      ]).then((modules) => {
        this.initChart(modules[0], modules[1], modules[2].default, modules[3].default, modules[4]);
      }).catch((e) => {
        console.error('Error when creating chart', e);
      });
    });
  }

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any, am4pluginsForceDirected: any): void {
    return;
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  empty(): void {
    this.isEmpty = true;
  }
}
