import { Component, OnInit } from '@angular/core';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {DashboardService} from './dashboard.service';
import {DataCategoryChart, DataChart, DataProgressByLevel, DataTimelineByLevel} from '../charts/charts';
import {DashboardCounters, periodicityOverallBimModelHealth} from './dashboard';
import {ColorService, colorVT} from '../galaxy/color.service';
import {zip} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import { IssuesService } from '../issues/issues.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public loaded = false;

  public dataCounters?: DashboardCounters;
  public dataResolvedPercentage: DataChart[] = [];

  public title = 'Dashboard';

  public dataIssuesByZoneAndDeadline: DataCategoryChart|null = null;
  public dataIssuesByTeamAndDeadline: DataCategoryChart|null = null;
  public dataIssuesByType: DataChart[] = [];
  public dataCriticalIssuesByTeam: DataChart[] = [];
  public dataCriticalIssuesByZone: DataChart[] = [];
  public dataIssuesByStatus: DataChart[] = [];
  public dataOverallBimModelHealth: DataCategoryChart|null = null;
  public dataTimelineByLevel: DataTimelineByLevel[] = [];
  public dataTimelineByLevelLabel = ['Not Started', 'On Time', 'Late 1-6 Days', 'Late 7+ Days', 'Completed'];
  public dataProgressByLevel: DataProgressByLevel[] = [];

  public periodicity = periodicityOverallBimModelHealth;
  public form: FormGroup;
  public loadingOBMH = false;
  public currentDate = new Date();

  constructor(
    private lps: LabelPageService,
    private ds: DashboardService,
    private fb: FormBuilder,
    private cs: ColorService,
    private is: IssuesService,
  ) {
    this.lps.setData({label: this.title},  this.title);
    zip(
      this.ds.getData(),
      this.is.filterIssues({ filter: [{ field: 'status', value: 'Open' }] }),
      this.is.filterIssues({ filter: [{field: 'status', value: 'In Progress'}] }),
      this.cs.getDataTypeColors()
    ).subscribe(([data, open, inProgress]) => {
      this.loaded = true;
      this.dataCounters = data.counters;

      this.dataIssuesByZoneAndDeadline = data.charts.issuesByZoneAndDeadline
        .length
        ? {
            legend: data.charts.issuesByZoneAndDeadline[0].aggregations.map(
              (item) => {
                return {
                  name: item.name,
                  color: this.cs.getColor('lateColor', item.name),
                };
              }
            ),
            items: data.charts.issuesByZoneAndDeadline.map((item) => {
              return {
                category: item.zone.name,
                values: item.aggregations.map((v) => v.number),
              };
            }),
          }
        : null;
      this.dataIssuesByTeamAndDeadline = data.charts.issuesByTeamAndDeadline
        .length
        ? {
            legend: data.charts.issuesByTeamAndDeadline[0].aggregations.map(
              (item) => {
                return {
                  name: item.name,
                  color: this.cs.getColor('lateColor', item.name),
                };
              }
            ),
            items: data.charts.issuesByTeamAndDeadline.map((item) => {
              return {
                category: item.team,
                values: item.aggregations.map((v) => v.number),
              };
            }),
          }
        : null;
      // this.dataIssuesByType = data.charts.issuesByType.map((item) => {
      //   return {
      //     name: item.type,
      //     value: item.number,
      //     color: this.cs.getColor('issueType', item.type),
      //   };
      // });

      // Issues by type workaround start
      const openedAndInProgress = {
        edges: [...open.edges, ...inProgress.edges],
        totalCount: open.totalCount + inProgress.totalCount,
      };
      let issuesByType: { [key: string]: number } = {};
      for(let edge of openedAndInProgress.edges) {
        const item = edge.node;
        const existing = Object.keys(issuesByType).find(key => key === item.type);
        if(existing) {
          issuesByType = { ...issuesByType, [`${item.type}`]: issuesByType[item.type] + 1 }
        } else {
          issuesByType = { ...issuesByType, [`${item.type}`]: 1 }
        }
      }
      this.dataIssuesByType = Object.keys(issuesByType).map((key) => {
        return {
          name: key,
          value: issuesByType[key],
          color: this.cs.getColor('issueType', key),
        };
      });
      // Issues by type workaround end

      this.dataCriticalIssuesByTeam = data.charts.criticalIssuesByTeam.map(
        (item, index) => {
          return {
            name: item.team,
            value: item.number,
            color:
              this.cs.getTypeColors('uniformat')[index]?.value ||
              this.cs.defaultColor,
          };
        }
      );
      this.dataCriticalIssuesByZone = data.charts.criticalIssuesByZone.map(
        (item) => {
          return {
            name: item.zone.name,
            value: item.number,
            color: item.zone.color,
          };
        }
      );
      this.dataIssuesByStatus = data.charts.issuesByStatus.map((item) => {
        return {
          name: item.status,
          value: item.number,
          color: this.cs.getColor('issueStatus', item.status),
        };
      });
      this.dataResolvedPercentage = [
        {
          name: 'Closed',
          value: data.counters.resolvedCount,
          color: this.cs.getColor('issueStatus', 'Closed'),
        },
        {
          name: 'Open',
          value: data.counters.openIssues,
          color: this.cs.getColor('issueStatus', 'Open'),
        },
      ];

      this.dataTimelineByLevel = data.charts.overallCoordinationTimeline.map(
        (item) => {
          const lateGroup = item.milestones.some(
            (el) => el.lateGroup === this.dataTimelineByLevelLabel[3]
          )
            ? this.dataTimelineByLevelLabel[3]
            : item.milestones.some(
                (el) => el.lateGroup === this.dataTimelineByLevelLabel[2]
              )
            ? this.dataTimelineByLevelLabel[2]
            : item.milestones.every(
                (el) => el.lateGroup === this.dataTimelineByLevelLabel[4]
              )
            ? this.dataTimelineByLevelLabel[4]
            : item.milestones.every((el) => new Date(el.date) > new Date())
            ? this.dataTimelineByLevelLabel[0]
            : this.dataTimelineByLevelLabel[1];
          const progress =
            item.milestones.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.completed,
              0
            ) / item.milestones.length;
          return {
            level: item.zone.name,
            startDate: item.milestones
              .filter((item2) => item2.pointType === 'start_task')
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime()
              )[0].date,
            endDate: item.milestones
              .filter((item2) => item2.pointType === 'end_task')
              .slice()
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )[0].date,
            color: this.cs.getColor('lateColor', lateGroup),
            currentDateColor:
              lateGroup === this.dataTimelineByLevelLabel[3] ||
              lateGroup === this.dataTimelineByLevelLabel[2]
                ? this.cs.getColor('lateColor', lateGroup)
                : '',
            progress: parseFloat(progress.toFixed(2)),
          };
        }
      );
      this.dataProgressByLevel = data.charts.overallCoordinationTimeline.map(
        (item) => {
          const lateGroup = item.milestones.some(
            (el) => el.lateGroup === this.dataTimelineByLevelLabel[3]
          )
            ? this.dataTimelineByLevelLabel[3]
            : item.milestones.some(
                (el) => el.lateGroup === this.dataTimelineByLevelLabel[2]
              )
            ? this.dataTimelineByLevelLabel[2]
            : item.milestones.every(
                (el) => el.lateGroup === this.dataTimelineByLevelLabel[4]
              )
            ? this.dataTimelineByLevelLabel[4]
            : item.milestones.every((el) => new Date(el.date) > new Date())
            ? this.dataTimelineByLevelLabel[0]
            : this.dataTimelineByLevelLabel[1];
          const progress =
            item.milestones.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.completed,
              0
            ) / item.milestones.length;
          return {
            level: item.zone.name,
            progress: parseFloat(progress.toFixed(2)),
            color: this.cs.getColor('lateColor', lateGroup),
          };
        }
      );
    });
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 4 * 7);
    const endDate = new Date();
    this.form = this.fb.group({
      periodicity: [this.periodicity[1].value, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [endDate, [Validators.required]]
    });
    this.getOBMH(this.periodicity[1].value, startDate, endDate);
    this.form.valueChanges.subscribe(data => {
      this.getOBMH(data.periodicity, data.startDate, data.endDate);
    });
  }

  ngOnInit(): void {
  }

  getOBMH(period: string, startDate: Date, endDate: Date): void {
    this.loadingOBMH = true;
    this.ds.getOverallBimModelHealth(
      period,
      formatDate(startDate, 'YYYY-MM-dd', 'en'),
      formatDate(endDate, 'YYYY-MM-dd', 'en')).subscribe(data => {
        if (data.length) {
          this.dataOverallBimModelHealth = data.length === 0 ? null : {
            legend: [{name: 'Number of issues', color: colorVT.red}, {name: 'Closed', color: colorVT.blue}],
            items: data.map(item => {
              return {
                category: item.dateStart,
                values: [item.total, item.closed],
                tooltip: '\nPeriod: ' +
                  formatDate(item.dateStart, 'MM/dd/YYYY', 'en') +
                  (item.dateStart !== item.dateEnd ? ' - ' + formatDate(item.dateEnd, 'MM/dd/YYYY', 'en') : '')
              };
            })
          };
        }
        else {
          this.dataOverallBimModelHealth = null;
        }
        this.loadingOBMH = false;
    });
  }

}
