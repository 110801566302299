import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataProgressByLevel} from '../charts';
import {scopeVTColor} from '../../galaxy/color.service';

@Component({
  selector: 'app-galaxy-progress-by-level',
  templateUrl: '../chart.component.html',
  styleUrls: ['./galaxy-progress-by-level.component.scss']
})
export class GalaxyProgressByLevelComponent extends AmChartsComponent {
  @Input() data: DataProgressByLevel[] = [];

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data.length) {
      this.empty();
      return;
    }

    am4core.useTheme(am4themesAnimated);
    const chart = am4core.create(this.idChart, am4charts.XYChart);

    const legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.data = scopeVTColor.colors.slice().reverse().map(item => {
      return {
        name: item.name,
        fill: item.value
      };
    });
    legend.fontSize = this.fontSize;
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    // Add data
    chart.data = this.data.map(item => {
      return {
        ...item,
        color: am4core.color(item.color)
      };
    });

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.dataFields.value = 'progress';
    xAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    xAxis.renderer.labels.template.fontSize = this.fontSize;
    xAxis.min = 0;
    xAxis.max = 100;
    xAxis.strictMinMax = true;
    xAxis.renderer.labels.template.adapter.add('text', (text: any) => {
      return text + '%';
    });

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'level';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    // smaller because so much values
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 10;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'progress';
    series.dataFields.categoryY = 'level';
    series.columns.template.propertyFields.stroke = 'color';
    series.columns.template.propertyFields.fill = 'color';
    series.columns.template.height = am4core.percent(30);
    series.columns.template.tooltipText = '{level}: {progress}%';
    series.columns.template.tooltipPosition = 'pointer';

    this.chart = chart;
  }
}
