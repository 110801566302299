import {Component, OnDestroy} from '@angular/core';
import {LabelPageService} from '../../base/topbar/app.label-page.service';
import {PdfPageService} from '../pdf-page.service';
import {PdfItem} from '../pdf-page';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class PdfPageScheduleComponent implements OnDestroy {

  public title = 'BIM Coordination Schedule';
  public scheduleList: PdfItem[] = [];
  public schedule: PdfItem|null = null;
  private subSlug: Subscription;
  constructor(
    private route: ActivatedRoute,
    private lps: LabelPageService,
    private ps: PdfPageService
  ) {
    this.subSlug = this.route.params.subscribe(routeParams => {
      this.init(routeParams.slug);
    });
  }

  ngOnDestroy(): void {
    this.subSlug.unsubscribe();
  }

  init(slug?: string): void {
    this.schedule = null;
    this.ps.getScheduleList().subscribe(data => {
      this.scheduleList = data.map(item => {
        const name = item.uploadedFile.filename.split('.')[0] || 'document';
        return {
          filename: item.uploadedFile.filename || 'document',
          name,
          url: encodeURIComponent(item.uploadedFile.publicUrl),
          slug: name.toLowerCase().replace(/[ &]/gi, '-')
        };
      });
      const selectPages = this.scheduleList.map(item => {
        return {
          name: item.name,
          routerLink: ['/bim-coordination-schedule', item.slug]
        };
      });
      this.schedule = this.scheduleList.find(item => slug === item.slug) || this.scheduleList[0];
      this.lps.setData({
        label: this.title, selectPages, activeRouterLink: ['/bim-coordination-schedule', this.schedule.slug + '']}, this.title);
    });
  }

}
