import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import { DataTimelineChart} from '../charts';
import {formatDate} from '@angular/common';
import {colorVT, scopeVTColor} from '../../galaxy/color.service';

@Component({
  selector: 'app-galaxy-timeline-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['./galaxy-timeline-chart.component.scss']
})
export class GalaxyTimelineChartComponent extends AmChartsComponent {
  @Input() data: DataTimelineChart|null = null;

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data) {
      this.empty();
      return;
    }

    am4core.useTheme(am4themesAnimated);
    const chart = am4core.create(this.idChart, am4charts.XYChart);

    const legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.data = scopeVTColor.colors.slice().reverse().map(item => {
      return {
        name: item.name,
        fill: item.value
      };
    });
    legend.fontSize = this.fontSize;
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    // Add data
    chart.data = this.data.items.map(item => {
      return {
        ...item,
        date: new Date(item.endDate),
        startDate: formatDate(item.startDate, 'MM/dd/YYYY', 'en'),
        endDate: formatDate(item.endDate, 'MM/dd/YYYY', 'en'),
        value: 1
      };
    });
    chart.maskBullets = false;
    const currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);

    const xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    xAxis.renderer.labels.template.fontSize = this.fontSize;
    xAxis.baseInterval = {
      timeUnit: 'day',
      count: 1
    };
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;


    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.max = 2;
    yAxis.strictMinMax = true;
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.baseGrid.disabled = true;
    yAxis.tooltip.disabled = true;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.stroke = this.colorLine;
    series.strokeWidth = 4;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 10;
    bullet.setStateOnChildren = true;
    bullet.tooltipText = '{nameTitle}\n{name}\n{completed}% Complete\nStart date: {startDate}\n Finish date: {endDate}';
    bullet.propertyFields.fill = 'color';
    bullet.propertyFields.stroke = 'color';
    bullet.states.create('hover');
    bullet.circle.states.create('hover').properties.radius = 14;

    // full timeline
    const series2 = chart.series.push(new am4charts.LineSeries());
    const date1 = new Date(this.data.startDate);
    const date2 = new Date(this.data.endDate);
    series2.data = [{date: date1, value: 1}, {date: date2, value: 1}];
    series2.dataFields.dateX = 'date';
    series2.dataFields.valueY = 'value';
    series2.stroke = am4core.color(this.colorLine);
    series2.strokeWidth = 4;

    // current date
    // if (currentDate > new Date(this.data.startDate) && currentDate < new Date(this.data.endDate)) {
    //   const series3 = chart.series.push(new am4charts.LineSeries());
    //   series3.data = [
    //     {date: currentDate, value: 1}];
    //   series3.dataFields.dateX = 'date';
    //   series3.dataFields.valueY = 'value';
    //   series3.stroke = am4core.color(this.colorLine);
    //
    //   const bullet2 = series3.bullets.push(new am4charts.Bullet());
    //   bullet2.stroke = am4core.color(this.colorLine);
    //   bullet2.fill = am4core.color(this.colorLine);
    //   bullet2.tooltipText = 'Today\n' + formatDate(currentDate, 'MM/dd/YYYY', 'en');
    //   // Add a triangle to act as am arrow
    //   const arrow = bullet2.createChild(am4core.Triangle);
    //   arrow.horizontalCenter = 'middle';
    //   arrow.verticalCenter = 'bottom';
    //   arrow.direction = 'bottom';
    //   arrow.width = 12;
    //   arrow.height = 25;
    // }

    // progress
    if (this.data.coordinationComplete) {
      const series4 = chart.series.push(new am4charts.LineSeries());
      const progressDate = Math.round(
        (new Date(this.data.endDate).getTime() - new Date(this.data.startDate).getTime())
        * this.data.coordinationComplete / 100
        + new Date(this.data.startDate).getTime()
      );
      series4.data = [
        {date: new Date(this.data.startDate), value: 1},
        {date: new Date(progressDate), value: 1}
      ];
      series4.dataFields.dateX = 'date';
      series4.dataFields.valueY = 'value';
      series4.stroke = am4core.color(colorVT.blue);
      series4.strokeWidth = 6;
    }

    this.chart = chart;
  }
}
