<div class="p-grid" *ngIf="loaded">
  <div class="p-col-12 p-xl-9">
    <div class="p-grid">
      <div class="p-col-12">
        <p-card header="Open Issues by Type">
          <app-donut-chart [height]="400" [data]="dataTypes" idChart="app-donut-chart-T"></app-donut-chart>
        </p-card>
      </div>
      <div class="p-xl-6 p-col-12">
        <p-card header="All Issues by Status">
          <app-donut-chart [height]="400" [data]="dataStatus" idChart="app-donut-chart-S"></app-donut-chart>
        </p-card>
      </div>
      <div class="p-xl-6 p-col-12">
        <p-card header="Open Issues by Deadline">
          <app-donut-chart [height]="400" [data]="dataOpenIssuesByDeadline" idChart="app-donut-chart-ID"></app-donut-chart>
        </p-card>
      </div>
<!--      <div class="p-col-12">-->
<!--        <p-card header="Issue Groups by Date">-->
<!--          <app-lines-chart [height]="400" [data]="dataIssuesByGroupHistory" idChart="app-lines-chart"></app-lines-chart>-->
<!--        </p-card>-->
<!--      </div>-->
      <div class="p-col-12">
        <p-card header="Open Issues by Team Responsible & by Deadline">
          <app-category-chart [height]="400" [data]="dataIssuesByTeamAndDeadline" idChart="app-category-chart-TD"></app-category-chart>
        </p-card>
      </div>
      <div class="p-col-12">
        <p-card *ngIf="dataTimeline">
          <div><h1>{{dataTimeline.coordinationComplete}}% <h5>Coordination complete</h5></h1></div>
          <app-galaxy-timeline-chart [height]="120" [data]="dataTimeline" idChart="app-galaxy-timeline-chart"></app-galaxy-timeline-chart>
        </p-card>
      </div>
      <div class="p-col-12">
        <p-card header="Overall BIM Model Health">
          <div class="p-grid" *ngIf="form">
            <form [formGroup]="form" class="p-col-fixed" style="width:300px; height: 435px">
              <h5>Select periodicity and time frame</h5>
              <div class="p-field">
                <label for="periodicity">Periodicity</label>
                <p-dropdown [options]="periodicity" formControlName="periodicity" inputId="periodicity"></p-dropdown>
              </div>
              <div class="p-field">
                <label for="startDate">Start Date</label>
                <p-calendar formControlName="startDate" [showIcon]="true" inputId="startDate"></p-calendar>
              </div>
              <div class="p-field">
                <label for="endDate">End Date</label>
                <p-calendar formControlName="endDate" [showIcon]="true" inputId="endDate" [maxDate]="currentDate"></p-calendar>
              </div>
            </form>
            <div class="p-col">
              <app-lines-chart *ngIf="!loadingOBMH" [height]="400" [data]="dataBimModelHealth" idChart="app-lines-chart"></app-lines-chart>
            </div>
          </div>
        </p-card>
      </div>
      <div class="p-col-12">
        <p-card header="Open Critical Issues by Team Responsible">
          <app-donut-chart [height]="400" [data]="dataCriticalIssuesByTeam" idChart="app-donut-chart-TR"></app-donut-chart>
        </p-card>
      </div>
    </div>
  </div>

  <div class="p-text-center p-col-12 p-xl-3" *ngIf="dataCounters">
    <p-card>
      <div class="p-mb-6">
        <h1>{{dataCounters.openIssues}}</h1>
        <div>Open Issues</div>
      </div>
      <div class="p-mb-6">
        <h1>{{dataCounters.cleanTimeAvg||0}}</h1>
        <div>Clear Time Average, Days</div>
      </div>
      <div class="p-mb-6">
        <h1>{{dataCounters.criticalIssues}}</h1>
        <div>Critical Issues</div>
      </div>
      <div class="p-mb-6">
        <app-donut-chart
          [height]="200"
          [data]="dataResolvedPercentage"
          idChart="app-donut-chart5"
          [isLegend]="false"
          [isCenterLegend]="true"
          [alignLabels]="false"
          [valueCenterLegend]="(dataCounters.resolvedPercentage||0)+'%'"
        ></app-donut-chart>
        <div>Closed Issues</div>
      </div>
      <app-export-issues icon="" *ngIf="level" [label]="'Download '+level?.name+' Log'" [idLevel]="level.id"></app-export-issues>
    </p-card>
  </div>
</div>
