import {Component, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import {LabelPage, LabelPageService} from './app.label-page.service';
import {BaseComponent} from '../base.component';
import {AuthService} from '../../auth/auth.service';
import {CurrentUser} from '../../auth/auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{

  private subscription: Subscription;
  private subscriptionCurrentUser: Subscription;

  public labelPage: LabelPage|null = null;

  public currentUser: null|CurrentUser = null;

  constructor(
    public lps: LabelPageService,
    public appBase: BaseComponent,
    private auth: AuthService,
    private router: Router
  ) {
      this.subscription = lps.labelPage.subscribe(data => {
        this.labelPage = data;
      });
      this.subscriptionCurrentUser = this.auth.currentUserSubject.subscribe(data => {
        this.currentUser = data;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionCurrentUser) {
      this.subscriptionCurrentUser.unsubscribe();
    }
  }

  signOut(): void {
    this.auth.signOut();
  }

  navigate({value}: any): void {
    this.router.navigate(value);
  }
}
