import {Component, OnDestroy} from '@angular/core';
import { WeatherService} from './weather.service';
import {Subscription} from 'rxjs';
import {GalaxyWeather} from './weather';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnDestroy {
  public weather: GalaxyWeather|null = this.ws.galaxyWeather.getValue();
  private subscription: Subscription;

  constructor(
    private ws: WeatherService
  ) {
    this.subscription = this.ws.galaxyWeather.subscribe(data => this.weather = data);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
