import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataChart} from '../charts';

@Component({
  selector: 'app-donut-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent extends AmChartsComponent {
  @Input() data: DataChart[] = [];
  @Input() isLegend = true;
  @Input() isCenterLegend = false;
  @Input() valueCenterLegend?: string;
  @Input() hiddenLabel = false;
  @Input() alignLabels = true;

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data.length) {
      this.empty();
      return;
    }
    const chart = am4core.create(this.idChart, am4charts.PieChart);

    // Add data
    chart.data = this.data.map(item => {
      return {
        ...item,
        color: am4core.color(item.color)
      };
    });

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'name';
    pieSeries.labels.template.text = '{value}';
    pieSeries.alignLabels = this.alignLabels;
    if (this.hiddenLabel) {
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
    }

    pieSeries.slices.template.propertyFields.fill = 'color';

    // legend
    if (this.isLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'right';
      chart.legend.fontSize = this.fontSize;
      chart.legend.labels.template.fill = am4core.color(this.textColor);
      chart.legend.valueLabels.template.disabled = true;
      // chart.legend.maxHeight = 160;
      chart.legend.scrollable = true;
    }

    if (this.isCenterLegend) {
      const label = pieSeries.createChild(am4core.Label);
      label.text = this.valueCenterLegend || '{values.value.sum}';
      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = '16';
      label.fill = am4core.color(this.textColor);
    }

    chart.innerRadius = am4core.percent(40);

    this.chart = chart;
  }
}
