<div class="layout-sidebar" (click)="appBase.onMenuClick()">
  <a [routerLink]="['/']" class="logo">
<!--    <div><img id="app-logo" src="assets/images/logo-d.png" alt="diamond-ng"/></div>-->
    <div><span class="app-name app-name-llc">Hudson Square Realty, LLC</span></div>
    <div><span class="app-name">Project Galaxy</span></div>
  </a>

  <div class="layout-menu-container">
    <ul class="layout-menu">
        <ng-container *ngFor="let item of model; let i = index;">
            <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
            <li *ngIf="item.separator" class="menu-separator"></li>
        </ng-container>
    </ul>
    <app-weather></app-weather>
    <div class="p-d-flex p-jc-center p-mt-3">
      <img src="assets/images/logo.png"/>
    </div>
  </div>
</div>
