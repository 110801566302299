import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {fromPromise} from 'rxjs/internal-compatibility';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CognitoUser, ISignUpResult} from 'amazon-cognito-identity-js';
import {CurrentUser, GQLAvatar} from './auth';
import {GHttpService} from '../galaxy/ghttp.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl = '';
  public confirmEmail = '';
  public currentUserSubject = new BehaviorSubject<CurrentUser|null>(null);

  constructor(
    private router: Router,
    private http: GHttpService
  ) {
  }

  isAuthenticated(): Observable<boolean> {
    this.cognitoUserToCurrentUser();
    return this.cognitoUser().pipe(
      map(data => {
        return !!data;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  cognitoUserToCurrentUser(): void {
    zip(this.cognitoUser(), this.getAvatar()).subscribe(([data, avatar]) => {
      if (!data) {
        this.currentUserSubject.next(null);
        return;
      }
      this.currentUserSubject.next(
        {
          email: data.username,
          name: data.attributes.name,
          company: data.attributes['custom:company'],
          picture: avatar,
          roleDescription: data.attributes['custom:role_description']
        }
      );
    }, () => {
      this.currentUserSubject.next(null);
    });
  }

  isAdmin(): Observable<boolean> {
    return this.cognitoUser().pipe(
      map(data => {
        return data.attributes['custom:is_admin'] === 'true';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  isContact(): Observable<boolean> {
    return this.cognitoUser().pipe(
      map(data => {
        return data.attributes['custom:is_contact'] === 'true';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  isApproved(): Observable<boolean> {
    return this.cognitoUser().pipe(
      map(data => {
        return data.attributes['custom:is_approved'] === 'true';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  cognitoUser(): Observable<CognitoUser|any> {
    return fromPromise(Auth.currentAuthenticatedUser());
  }

  signIn(username: string, password: string): Observable<CognitoUser|any> {
    return fromPromise(Auth.signIn(username, password));
  }

  signUp(username: string, password: string, name: string, company: string): Observable<ISignUpResult> {
    return fromPromise(Auth.signUp({
      username,
      password,
      attributes: {
        name,
        'custom:company': company
      }
    }));
  }

  forgotPassword(email: string): Observable<any> {
    return fromPromise(Auth.forgotPassword(email));
  }

  forgotPasswordSubmit(email: string, code: string, newPassword: string): Observable<void> {
    return fromPromise(Auth.forgotPasswordSubmit(email, code, newPassword));
  }

  confirmSignUp(username: string, code: string): Observable<any> {
    return fromPromise(Auth.confirmSignUp(username, code));
  }

  resendConfirmationCode(username: string): Observable<any> {
    return fromPromise(Auth.resendSignUp(username));
  }

  signOut(isRedirect = true): void {
    fromPromise(Auth.signOut()).subscribe(
      () => {
        this.currentUserSubject.next(null);
        this.redirectUrl = '';
        this.confirmEmail = '';
        if (isRedirect) {
          this.router.navigate(['/sign-in']).then();
        }
      },
      (error => {
        console.log('error signing out: ', error);
      })
    );
  }

  jwtToken(): Observable<string> {
    return fromPromise(Auth.currentSession()).pipe(
      map(data => data.getIdToken().getJwtToken())
    );
  }

  getAvatar(): Observable<string> {
    return this.http.graphql(GQLAvatar, ['user', 'auth', 'whoAmI', 'picture']);
  }
}
