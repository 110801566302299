import {Component, OnDestroy} from '@angular/core';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {LevelAnalysisService} from './level-analysis.service';
import {ActivatedRoute} from '@angular/router';
import {Level, LevelCounters} from './level-analysis';
import {DataCategoryChart, DataChart, DataTimelineChart} from '../charts/charts';
import {Subscription, zip} from 'rxjs';
import {colorR, GqlFilter} from '../galaxy/g';
import {ColorService, colorVT} from '../galaxy/color.service';
import {formatDate} from '@angular/common';
import {periodicityOverallBimModelHealth} from '../dashboard/dashboard';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { IssuesService } from '../issues/issues.service';

@Component({
  selector: 'app-level-analysis',
  templateUrl: './level-analysis.component.html',
  styleUrls: ['./level-analysis.component.scss']
})
export class LevelAnalysisComponent implements OnDestroy {
  public title = 'Analysis';
  public level: Level|null = null;
  public loaded = false;
  public dataCounters?: LevelCounters;
  public dataPriority: DataChart[] = [];
  public dataResolvedPercentage: DataChart[] = [];
  public dataTypes: DataChart[] = [];
  public dataStatus: DataChart[] = [];
  public dataGroups: DataChart[] = [];
  // public dataIssuesByGroupHistory: DataCategoryChart|null = null;
  private subSlug: Subscription;

  public dataTimeline: DataTimelineChart|null = null;
  public dataCriticalIssuesByTeam: DataChart[] = [];
  public dataIssuesByTeamAndDeadline: DataCategoryChart|null = null;
  public dataOpenIssuesByDeadline: DataChart[] = [];
  public periodicity = periodicityOverallBimModelHealth;
  public form?: FormGroup;
  public loadingOBMH = false;
  public dataBimModelHealth: DataCategoryChart|null = null;
  public currentDate = new Date();

  constructor(
    private lps: LabelPageService,
    private las: LevelAnalysisService,
    private route: ActivatedRoute,
    private cs: ColorService,
    private fb: FormBuilder,
    private is: IssuesService,
  ) {
    this.subSlug = this.route.params.subscribe(routeParams => {
      this.init(routeParams.slug);
    });
  }

  ngOnDestroy(): void {
    this.subSlug.unsubscribe();
  }

  init(slug?: string): void {
    this.loaded = false;
    this.las.getAllLevels().subscribe(data => {
      this.level = data.find(item => slug === item.slug) || data[0];
      if (this.level) {
        const title = this.level.name + ' ' + this.title;
        const selectPages = data.map(item => {
          return {
            name: item.name,
            routerLink: ['/analysis', item.slug]
          };
        });
        this.lps.setData({label: title, selectPages, activeRouterLink: ['/analysis', this.level.slug]}, title);
        this.getStats(this.level.id);
      }
    });
  }

  getStats(levelId: string): void {
    zip(
      this.las.getData(levelId),
      this.is.filterIssues({
        filter: [
          { field: 'status', value: 'Open' },
          { field: 'zone', value: levelId },
        ],
      }),
      this.is.filterIssues({
        filter: [
          { field: 'status', value: 'In Progress' },
          { field: 'zone', value: levelId },
        ],
      }),
      this.cs.getDataTypeColors()
    ).subscribe(([data, open, inProgress]) => {
      this.loaded = true;
      this.dataCounters = data.counters;
      this.dataPriority = data.charts.issuesByPriority.map((item, index) => {
        return {
          name: item.priority,
          value: item.number,
          color: this.cs.getColor('issuePriority', item.priority),
        };
      });
      // this.dataTypes = data.charts.issuesByType.map((item, index) => {
      //   return {
      //     name: item.type,
      //     value: item.number,
      //     color: this.cs.getColor('issueType', item.type),
      //   };
      // });

      // Issues by type workaround start
      const openedAndInProgress = {
        edges: [...open.edges, ...inProgress.edges],
        totalCount: open.totalCount + inProgress.totalCount,
      };
      let issuesByType: { [key: string]: number } = {};
      for (let edge of openedAndInProgress.edges) {
        const item = edge.node;
        const existing = Object.keys(issuesByType).find(
          (key) => key === item.type
        );
        if (existing) {
          issuesByType = {
            ...issuesByType,
            [`${item.type}`]: issuesByType[item.type] + 1,
          };
        } else {
          issuesByType = { ...issuesByType, [`${item.type}`]: 1 };
        }
      }
      this.dataTypes = Object.keys(issuesByType).map((key) => {
        return {
          name: key,
          value: issuesByType[key],
          color: this.cs.getColor('issueType', key),
        };
      });
      // Issues by type workaround end

      this.dataStatus = data.charts.issuesByStatus.map((item, index) => {
        return {
          name: item.status,
          value: item.number,
          color: this.cs.getColor('issueStatus', item.status),
        };
      });
      if (data.charts.issuesByZoneAndDeadline.length) {
        this.dataOpenIssuesByDeadline =
          data.charts.issuesByZoneAndDeadline[0].aggregations.map((item) => {
            return {
              name: item.name,
              value: item.number,
              color: this.cs.getColor('lateColor', item.name),
            };
          });
      }
      this.dataResolvedPercentage = [
        {
          name: 'Closed',
          value: data.counters.resolvedCount,
          color: this.cs.getColor('issueStatus', 'Closed'),
        },
        {
          name: 'Open',
          value: data.counters.openIssues,
          color: this.cs.getColor('issueStatus', 'Open'),
        },
      ];
      this.dataTimeline = data.charts.coordinationTimeline.length
        ? {
            coordinationComplete: data.counters.coordinationComplete,
            startDate: data.charts.coordinationTimeline
              .filter((item) => item.pointType === 'start_task')
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime()
              )[0].date,
            endDate: data.charts.coordinationTimeline
              .filter((item) => item.pointType === 'end_task')
              .slice()
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )[0].date,
            items: data.charts.coordinationTimeline
              .filter((item) => item.pointType === 'end_task')
              .map((item) => {
                return {
                  name: item.name,
                  endDate: item.date,
                  startDate:
                    data.charts.coordinationTimeline.find(
                      (item2) =>
                        item2.number === item.number &&
                        item2.pointType === 'start_task'
                    )?.date || item.date,
                  completed: item.completed,
                  nameTitle: 'Milestone #' + item.number,
                  lateGroup: item.lateGroup,
                  color: this.cs.getColor('lateColor', item.lateGroup),
                };
              }),
          }
        : null;
      this.dataCriticalIssuesByTeam = data.charts.criticalIssuesByTeam.map(
        (item, index) => {
          return {
            name: item.team,
            value: item.number,
            color:
              this.cs.getTypeColors('uniformat')[index]?.value ||
              this.cs.defaultColor,
          };
        }
      );
      if (data.charts.issuesByTeamAndDeadline.length) {
        this.dataIssuesByTeamAndDeadline = {
          legend: data.charts.issuesByTeamAndDeadline[0].aggregations.map(
            (item) => {
              return {
                name: item.name,
                color: this.cs.getColor('lateColor', item.name),
              };
            }
          ),
          items: data.charts.issuesByTeamAndDeadline.map((item) => {
            return {
              category: item.team,
              values: item.aggregations.map((v) => v.number),
            };
          }),
        };
      } else {
        this.dataIssuesByTeamAndDeadline = null;
      }
    });
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 4 * 7);
    const endDate = new Date();
    this.form = this.fb.group({
      periodicity: [this.periodicity[1].value, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [endDate, [Validators.required]]
    });
    this.getBMH(levelId, this.periodicity[1].value, startDate, endDate);
    this.form.valueChanges.subscribe(data => {
      this.getBMH(levelId, data.periodicity, data.startDate, data.endDate);
    });
  }
  getBMH(id: string, period: string, startDate: Date, endDate: Date): void {
    this.loadingOBMH = true;
    this.las.getBimModelHealth(
      id,
      period,
      formatDate(startDate, 'YYYY-MM-dd', 'en'),
      formatDate(endDate, 'YYYY-MM-dd', 'en')).subscribe(data => {
      if (data.length) {
        this.dataBimModelHealth = data.length === 0 ? null : {
          legend: [{name: 'Number of issues', color: colorVT.red}, {name: 'Closed', color: colorVT.blue}],
          items: data.map(item => {
            return {
              category: item.dateStart,
              values: [item.total, item.closed],
              tooltip: '\nPeriod: ' +
                formatDate(item.dateStart, 'MM/dd/YYYY', 'en') +
                (item.dateStart !== item.dateEnd ? ' - ' + formatDate(item.dateEnd, 'MM/dd/YYYY', 'en') : '')
            };
          })
        };
      }
      else {
        this.dataBimModelHealth = null;
      }
      this.loadingOBMH = false;
    });
  }
}
