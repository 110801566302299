export interface Contact {
  name: string;
  company: string;
  email: string;
  picture: string;
  roleDescription: string;
}

export const GQLAllContacts = `
  query q($after: String) {
    user {
      contacts(after: $after) {
        edges {
          node {
            email
            name
            company
            roleDescription
            picture
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
