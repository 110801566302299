import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataCategoryChart} from '../charts';


@Component({
  selector: 'app-category-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['./category-chart.component.scss']
})
export class CategoryChartComponent extends AmChartsComponent {
  @Input() data: DataCategoryChart|null = null;

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data) {
      this.empty();
      return;
    }

    am4core.useTheme(am4themesAnimated);

    const chart = am4core.create(this.idChart, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = this.data.items.map(item => {
      return {
        ...item,
        ...item.values
      };
    });

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color(this.textColor);
    chart.legend.position = 'top';
    chart.legend.fontSize = this.fontSize;

    // X axis
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    // smaller because so much values
    categoryAxis.renderer.labels.template.fontSize = 10;


    // categoryAxis.renderer.labels.template.truncate = false;
    // categoryAxis.renderer.labels.template.wrap = true;

    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.verticalCenter = 'top';
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';


    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.events.on('sizechanged', (ev: any) => {
      const axis = ev.target;
      axis.renderer.labels.template.maxWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    });


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;
    valueAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    valueAxis.renderer.labels.template.fontSize = this.fontSize;

    this.data.legend.forEach(({name, color}, index) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(40);
      series.columns.template.tooltipText = '{name}: {valueY.value}';
      series.name = name;
      series.dataFields.categoryX = 'category';
      series.dataFields.valueY = index.toString();
      series.stacked = true;
      series.columns.template.fill = am4core.color(color);
      series.columns.template.fillOpacity = 1;
      series.columns.template.strokeWidth = 0;

      //
      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = '{valueY.value}';
      bullet.label.fill = am4core.color('#ffffff');
      bullet.label.fontSize = this.fontSize;
      bullet.label.hideOversized = true;
      bullet.locationY = 0.5;
    });

    // grid colors
    chart.plotContainer.background.strokeWidth = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.renderer.grid.template.stroke = am4core.color(this.colorLine);
    valueAxis.renderer.grid.template.strokeWidth = 1;
    valueAxis.renderer.grid.template.strokeDasharray = '3,3';
    valueAxis.renderer.grid.template.strokeOpacity = .8;

    this.chart = chart;
  }
}
