export interface CreateUploadedFileMutationPayload {
  uploadedFile: {id: string};
  credentials: string;
}

export interface CreateUploadedFileMutationInput {
  filename: string;
  contentType: string;
}

export interface CreateFileMutationInput {
  uploadedFile: string;
}

export interface UploadedFile {
  id: string;
  filename: string;
  contentType: string;
  publicUrl: string;
}

export const uploadedFileSegment = `
  id
  filename
  contentType
  publicUrl
`;

export const PDFDocumentSegment = `
  id
  uploadedFile {
    ${uploadedFileSegment}
  }
`;

export interface PDFDocument {
  id: string;
  uploadedFile: UploadedFile;
}

export interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
