import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {GHttpService} from '../galaxy/ghttp.service';
import {IResizeImageOptions} from './files';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private http: GHttpService,
    private auth: AuthService
  ) { }

  private _download(url: string, filename: string, token?: string): void {
    const link = document.createElement('a');
    const dUrl = token ? url + '?jwt=' + token : url;
    link.setAttribute('href', dUrl);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  download(url: string, filename: string = '', isToken = true): void{
    if (isToken) {
      this.auth.jwtToken().subscribe(token => this._download(url, filename, token));
    }
    else {
      this._download(url, filename);
    }
  }

  open(url: string): void{
    this.showLink(url).subscribe(
      (tUrl) => {
        const link = document.createElement('a');
        link.setAttribute('href', tUrl);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    );
  }

  showLink(url: string): Observable<string> {
    return this.auth.jwtToken().pipe(
      switchMap((token) => {
        return of(url + '?jwt=' + token);
      })
    );
  }

  isImage(contentType: string): boolean {
    return contentType.indexOf('image') === 0;
  }

  isPdf(contentType: string): boolean {
    return contentType === 'application/pdf';
  }

  resizeImage(settings: IResizeImageOptions): Promise<Blob> {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = (dataURI: string) => {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ia], {type: mime});
    };
    function resize(): Blob {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    }


    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  }
}
