import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {
  public loading = false;
  public title = 'Reset Password';
  public messagesValue: Message[] = [];
  public form: FormGroup = this.fb.group({
    code: ['', [Validators.required]],
    newPassword: ['', [Validators.required]]
  });
  public email = this.authService.confirmEmail;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Reset Your Password | ' + environment.nameSite);
  }

  ngOnInit(): void {
    if (!this.email) {
      this.router.navigate(['/reset-password']).then();
    }
  }

  onSubmit(): void {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.authService.forgotPasswordSubmit(this.email, value.code, value.newPassword).subscribe(
        () => {
          this.router.navigate(['/sign-in']).then();
          this.loading = false;
        },
        (error => {
          this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
          this.loading = false;
        })
      );
    }
  }
}
