<div class="layout-wrapper layout-static layout-sidebar-darkgray" [ngClass]="{
  'layout-overlay-active': overlayMenuActive,
  'layout-mobile-active': staticMenuMobileActive,
  'layout-static-inactive': staticMenuDesktopInactive
}" (click)="onLayoutClick()">

  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

  </div>

  <app-menu></app-menu>

  <div class="layout-mask modal-in"></div>
</div>
