import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, expand, map, reduce, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {GQLBackError, GQLGetFullFunction, GqlPagination, GqlType, GqlTypeValue, UploadData, VariableGQL} from './g';
import {Router} from '@angular/router';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Auth} from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class GHttpService {
  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  jwtToken(): Observable<string> {
    return fromPromise(Auth.currentSession()).pipe(
      map(data => data.getIdToken().getJwtToken())
    );
  }

  private handleError(errors: any): Observable<never> {
    if (typeof errors === 'string') {
      console.error(errors);
      return throwError(errors);
    }
    if (errors instanceof HttpErrorResponse) {
      if (errors.error instanceof ErrorEvent) {
        console.error('An error occurred:', errors.error.message);
      } else {
        console.error(
          `Backend returned code ${errors.status}, ` +
          `body was: ${errors.error}`);
      }
    }
    else if (errors.length) {
      const errorsString = errors.map((error: GQLBackError) => {
        if (['Issue not found'].includes(error.message)) {
          this.router.navigateByUrl('/404', {skipLocationChange: true});
        }
        return '[' + error.type + '|' + error.code + '] ' + error.message;
      });
      return throwError(errorsString.join('\n'));
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  private _graphql<T>(query: string, name: string|string[], variables: object = {}): Observable<T> {
    const body = {
      query,
      variables
    };
    const observable = this.jwtToken().pipe(
      switchMap(token => {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          })
        };
        return this.httpClient.post<GqlType<T>>(
          environment.graphQLServer,
          body,
          options
        );
      })
    );
    return observable.pipe(
      switchMap<GqlType<T>|GqlType<GqlTypeValue<T>>|GqlType<GqlTypeValue<GqlTypeValue<T>>>|GqlType<GqlTypeValue<GqlTypeValue<GqlTypeValue<T>>>>, Observable<T>>(({data, errors}) => {
        if (errors) {
          return throwError(errors);
        }
        if (typeof name === 'string') {
          const dataL = data[name] as T;
          return of(dataL);
        }
        else {
          if (name.length === 1) {
            const dataL = data[name[0]] as T;
            return of(dataL);
          }
          else if (name.length === 2) {
            const dataL = data[name[0]] as GqlTypeValue<T>;
            return of(dataL[name[1]]);
          }
          else if (name.length === 3) {
            const dataL = data[name[0]] as GqlTypeValue<GqlTypeValue<T>>;
            return of(dataL[name[1]][name[2]]);
          }
          else if (name.length === 4) {
            const dataL = data[name[0]] as GqlTypeValue<GqlTypeValue<GqlTypeValue<T>>>;
            return of(dataL[name[1]][name[2]][name[3]]);
          }
          else {
            return throwError('JS: _graphql(name: error)');
          }
        }
      }),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }
  private _graphqlGetFull<T>(GQL: string, variables: object = {}, name: string|string[] = 'x'): Observable<GqlPagination<T>> {
    return this._graphql<GqlPagination<T>>(GQL, name, variables).pipe(
      expand(data => {
        if (!data.pageInfo) {
          console.error('pageInfo not specified');
          return throwError('pageInfo not specified');
        }
        if (data.pageInfo.hasNextPage) {
          return this._graphql(GQL, name, {
            ...variables,
            after: data.pageInfo.endCursor
          });
        }
        else {
          return EMPTY;
        }
      }),
      reduce<GqlPagination<T>>((accData, data) => {
        return {
          ...accData,
          edges: accData.edges.concat(data.edges)
        };
      })
    );
  }

  private _graphqlSegmentGetFull<T>(
    queryName: string,
    queryItem: string,
    variables: object = {},
    variablesGQL: VariableGQL[] = []): Observable<GqlPagination<T>> {
    return this._graphqlGetFull(GQLGetFullFunction(queryName, queryItem, variablesGQL), variables);
  }

  graphql<T>(query: string, name: string|string[], variables?: object): Observable<T> {
    return this._graphql<T>(query, name, variables);
  }

  graphqlGetFull<T>(GQL: string, variables: object = {}, name: string|string[] = 'x'): Observable<T[]> {
    return this._graphqlGetFull<T>(GQL, variables, name).pipe(
      map(data => data.edges.map(item => item.node))
    );
  }

  graphqlGetFullPagination<T>(
    GQL: string, variables: object = {}, name: string|string[] = 'x'): Observable<GqlPagination<T>> {
    return this._graphqlGetFull<T>(GQL, variables, name);
  }

  graphqlSegmentGetFull<T>(
    queryName: string, queryItem: string,  variables: object = {}, variablesGQL: VariableGQL[] = []): Observable<T[]> {
    return this._graphqlSegmentGetFull<T>(queryName, queryItem, variables, variablesGQL).pipe(
      map(data => data.edges.map(item => item.node))
    );
  }

  graphqlAnon<T>(query: string, name: string, variables: object = {}): Observable<T> {
    const body = {
      query,
      variables
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<GqlType<T>>(environment.graphQLServer, body, options).pipe(
      switchMap<GqlType<T>, Observable<T>>(({data, errors}) => {
        if (errors) {
          return throwError(errors);
        }
        return of(data[name]);
      }),
      catchError(this.handleError)
    );
  }

  uploadFileS3(file: File|Blob, uploadData: UploadData): Observable<any> {
    const formData = new FormData();
    const dataOptions = {
      key: uploadData.fields.key,
      acl: uploadData.fields.acl,
      'Content-Type': uploadData.fields['Content-Type'],
      'X-Amz-Credential': uploadData.fields['x-amz-credential'],
      'X-Amz-Algorithm': uploadData.fields['x-amz-algorithm'],
      'X-Amz-Date': uploadData.fields['x-amz-date'],
      'X-Amz-Signature': uploadData.fields['x-amz-signature'],
      Policy: uploadData.fields.policy,
      file
    };

    Object.entries(dataOptions).forEach(
      ([key, value]) => formData.append(key, value)
    );
    return this.httpClient.post(
      uploadData.url,
      formData,
      {
        reportProgress: false,
        responseType: 'text'
      }
    );
  }
}
