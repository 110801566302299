<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h2 class="p-mb-6">{{title}}</h2>
  <p>
    We have sent a code by email to {{email}}.<br/>
    Enter it below to verify your Email address.
  </p>
  <div class="p-fluid">
    <div class="p-field">
      <label>Code</label>
      <input pInputText formControlName="code" required autocomplete="one-time-code"/>
    </div>
  </div>
  <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
  <button class="p-mt-3" pButton label="Verify Email" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
  <br>
  <p class="p-d-flex p-ai-center">
    <span class="p-mr-2">Didn't receive a code?</span>
    <button pButton type="button" (click)="resent()" class="p-button-text" [loading]="loadingResent">Resent Code</button>
  </p>
</form>
