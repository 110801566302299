import { Component, OnInit } from '@angular/core';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {ContactsService} from './contacts.service';
import {Contact} from './contacts';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  public title = 'Contacts';
  public contacts: Contact[] = [];
  constructor(
    private lps: LabelPageService,
    private cs: ContactsService
  ) {
    this.lps.setData({label: this.title}, this.title);
    this.cs.getContacts().subscribe(data => this.contacts = data);
  }

  ngOnInit(): void {
  }

  getFIO(str: string): string {
    return str.split(' ').map(item => item.slice(0, 1)).join('');
  }

}
