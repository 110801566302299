export interface CurrentUser {
  email: string;
  name: string;
  company: string;
  picture?: string;
  roleDescription?: string;
}

export interface CognitoUserAttributes {
  name?: string;
  'custom:company'?: string;
  'custom:role_description'?: string;
}

export const GQLAvatar = `
  query q {
    user {
      auth {
        whoAmI {
          picture
        }
      }
    }
  }
`;
