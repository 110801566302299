import {Component, OnDestroy} from '@angular/core';
import {LabelPageService} from '../../base/topbar/app.label-page.service';
import {PdfPageService} from '../pdf-page.service';
import {PdfItem} from '../pdf-page';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PdfPagePlanComponent implements OnDestroy {
  public title = 'BIM Execution Plan';
  public planList: PdfItem[] = [];
  public plan: PdfItem|null = null;

  private subSlug: Subscription;
  constructor(
    private route: ActivatedRoute,
    private lps: LabelPageService,
    private ps: PdfPageService
  ) {
    this.subSlug = this.route.params.subscribe(routeParams => {
      this.init(routeParams.slug);
    });
  }

  ngOnDestroy(): void {
    this.subSlug.unsubscribe();
  }

  init(slug?: string): void {
    this.plan = null;
    this.ps.getPlanList().subscribe(data => {
      this.planList = data.map(item => {
        const name = item.uploadedFile.filename.split('.')[0] || 'document';
        return {
          filename: item.uploadedFile.filename || 'document',
          name,
          url: encodeURIComponent(item.uploadedFile.publicUrl),
          slug: name.toLowerCase().replace(/[ &]/gi, '-')
        };
      });
      const selectPages = this.planList.map(item => {
        return {
          name: item.name,
          routerLink: ['/bim-execution-plan', item.slug]
        };
      });
      this.plan = this.planList.find(item => slug === item.slug) || this.planList[0];
      this.lps.setData({
        label: this.title, selectPages, activeRouterLink: ['/bim-execution-plan', this.plan.slug + '']}, this.title);
    });
  }

}
