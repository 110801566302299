export interface GqlTypeValue<T> {
  [key: string]: T;
}

export interface GqlType<T> {
  data: GqlTypeValue<T>;
  errors: GQLBackError[];
}

export interface GQLBackError {
  code: string;
  message: string;
  trace: string[];
  type: string;
}

export interface GqlEdges<T> {
  node: T;
}

interface GQLError {
  message: string;
  code: string;
}

export interface GQLOnlyError {
  [fieldName: string]: GQLError[];
  other_field: GQLError[];
  nonFieldErrors: GQLError[];
}

export interface GQLOnlyErrorNew3 {
  [fieldName: string]: string;
}

export interface GQLOnlyErrorNew {
  field: string;
  messages: string[];
}

export interface GQLOnlyErrorNew2 {
  [fieldName: string]: GQLOnlyErrorNew[];
  other_field: GQLOnlyErrorNew[];
  nonFieldErrors: GQLOnlyErrorNew[];
}

export interface GQLOnlyErrorResult {
  errors: GQLOnlyErrorNew[];
}

export interface GQLSuccessResult {
  success: boolean;
}

export interface GQLOnlyError2Result {
  errors: GQLOnlyErrorNew2|null;
}

export interface GQLOnlyErrorSuccessResult {
  success: boolean;
  errors: GQLOnlyError|null;
}

export interface GQLOnlyErrorSuccess2Result {
  success: boolean;
  errors: GQLOnlyErrorNew3|null;
}

export interface GqlPagination<T> {
  edges: GqlEdges<T>[];
  pageInfo?: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
  };
  totalCount: number;
}

export interface GqlInputPagination {
  id?: string;
  last?: number;
  first?: number;
  search?: string;
  before?: string;
  after?: string;
  offset?: number;
}

export const SEARCH_LIMIT = 20;

export interface Ordering {
  field: string;
  direction?: 'ASC'|'DESC';
}
export interface GqlInputOrderBy {
  order?: Ordering[];
}

export interface GqlFilter {
  field: string;
  value: string;
}

export interface GqlInputFilter {
  filter?: GqlFilter[];
}

export interface GqlInputPOF extends GqlInputPagination, GqlInputOrderBy, GqlInputFilter {
}

export function resultErrorToListString(gqlErrors: GQLOnlyError|GQLOnlyErrorNew3|null): string[] {
  if (gqlErrors === null) { return []; }
  const errorsStringList: string[] = [];
  Object.entries(gqlErrors).forEach(([nameField, errorList]) => {
    if (typeof errorList === 'string') {
      errorsStringList.push(errorList + (['other_field', 'nonFieldErrors'].includes(nameField) ? '' : '(' + nameField + ')'));
    }
    else {
      const errors = errorList as GQLError[];
      errors.forEach(item => {
        const message = item.message + (['other_field', 'nonFieldErrors'].includes(nameField) ? '' : '(' + nameField + ')');
        errorsStringList.push(message);
      });
    }
  });
  return errorsStringList;
}

export function resultErrorNewToListString(gqlErrors: GQLOnlyErrorNew[]): string[] {
  const errorsStringList: string[] = [];
  gqlErrors.forEach((error) => {
    error.messages.forEach(item => {
      const message = item + (['other_field', 'nonFieldErrors'].includes(error.field) ? '' : '(' + error.field + ')');
      errorsStringList.push(message);
    });
  });
  return errorsStringList;
}

export function resultErrorNew2ToListString(gqlErrors: GQLOnlyErrorNew2|null): string[] {
  if (gqlErrors === null) { return []; }
  const errorsStringList: string[] = [];
  Object.entries(gqlErrors).forEach(([nameField, errorList]) => {
    errorList.forEach(item => {
      item.messages.forEach(im => {
        const message = im + (['other_field', 'nonFieldErrors'].includes(nameField) ? '' : '(' + nameField + ')');
        errorsStringList.push(message);
      });
    });
  });
  return errorsStringList;
}

export interface UploadData {
  url: string;
  fields: {
    'Content-Type': string;
    key: string;
    acl: string;
    policy: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-signature': string;
  };
}

export interface EnumValuesItem {
  key: string;
  value: string;
}

export interface GQLEnumValuesItem {
  enumValues: EnumValuesItem[];
}

export interface UserForName {
  lastName: string;
  firstName: string;
  email: string;
}

export interface UserForAvaName extends UserForName{
  id: string;
  avatar?: string;
  color: string;
}

export interface ObjectIdName {
  id: string;
  name: string;
}

export interface VariableGQL {
  name: string;
  typeGQL: string;
}

const defaultVariablesGQL: VariableGQL[] = [
  {name: 'after', typeGQL: 'String'}
];

export function GQLGetFullFunction(name: string, fragmentItem: string, variablesGQL: VariableGQL[] = []): string {
  const qStr = [...defaultVariablesGQL, ...variablesGQL].map(item => {
    return '$' + item.name + ': ' + item.typeGQL;
  }).join(', ');
  const nStr = [...defaultVariablesGQL, ...variablesGQL].map(item => {
    return item.name + ': $' + item.name;
  }).join(', ');
  return `
    query q(` + qStr + `) {
      x: ` + name + `(` + nStr + `) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ` + fragmentItem + `
          }
        }
      }
    }
  `;
}


export interface InputOrdering {
  id: string;
  newPosition: number;
}

export interface FieldAndHeader {
  field: string;
  header: string;
  inactive?: boolean;
  filter?: string;
  suggestionsName?: string;
}

function shadeColor(col: any, p: number): string {
  const R = parseInt(col.substring(1, 3), 16);
  const G = parseInt(col.substring(3, 5), 16);
  const B = parseInt(col.substring(5, 7), 16);
  const currTotalDark = (255 * 3) - (R + G + B);

  // calculate how much of the current darkness comes from the different channels
  const RR = ((255 - R) / currTotalDark);
  const GR = ((255 - G) / currTotalDark);
  const BR = ((255 - B) / currTotalDark);

  // calculate how much darkness there should be in the new color
  const newTotalDark = ((255 - 255 * (p / 100)) * 3);

  // make the new channels contain the same % of available dark as the old ones did
  const NR = 255 - Math.round(RR * newTotalDark);
  const NG = 255 - Math.round(GR * newTotalDark);
  const NB = 255 - Math.round(BR * newTotalDark);

  const RO = ((NR.toString(16).length === 1) ? '0' + NR.toString(16) : NR.toString(16));
  const GO = ((NG.toString(16).length === 1) ? '0' + NG.toString(16) : NG.toString(16));
  const BO = ((NB.toString(16).length === 1) ? '0' + NB.toString(16) : NB.toString(16));

  return '#' + RO + GO + BO; }

const color = '#492764';
export function colorR(i: number): string {
  const ko = (i % 10) * 6 + 35 - parseInt(String(i / 10), 10);
  return shadeColor(color, ko);
}
