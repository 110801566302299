<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h2 class="p-mb-6">{{title}}</h2>

  <div class="p-fluid">
    <div class="p-field">
      <label>Company</label>
      <input pInputText formControlName="company" autocomplete="organization"/>
    </div>
    <div class="p-field">
      <label>Name</label>
      <input pInputText formControlName="name" autocomplete="name"/>
    </div>
    <div class="p-field">
      <label>Email</label>
      <input pInputText formControlName="email" autocomplete="email"/>
    </div>
    <div class="p-field">
      <label>Password</label>
      <p-password [toggleMask]="true" formControlName="password" autocomplete="new-password"></p-password>
    </div>
  </div>
  <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
  <button class="p-mt-3" pButton label="Request" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
  <br>
  <p>Already have an account? <a [routerLink]="['/sign-in']">Log In</a></p>

</form>
