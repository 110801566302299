<p-card>
  <div class="p-d-flex">
    <div class="p-mr-auto">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="p-field">
          <label>Name</label>
          <input pInputText formControlName="name" autocomplete="name" required/>
        </div>
        <div class="p-field">
          <label>Company</label>
          <input pInputText formControlName="company" autocomplete="organization" required/>
        </div>
        <div class="p-field">
          <label>Role Description</label>
          <input pInputText formControlName="roleDescription" autocomplete="organization-title"/>
        </div>

        <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
        <button class="p-mt-3" pButton label="Update Profile" type="submit" [disabled]="!form.valid" [loading]="loading"></button>

      </form>
      <div class="p-mt-6">
        <a routerLink="change-password">Change password</a>
      </div>
    </div>
    <div class="upload-block">
      <div class="image" (click)="!loadingAva?fileInput.click():false">
        <i *ngIf="!avatar" class="pi pi-user"></i>
        <i *ngIf="loadingAva" class="pi pi-spin pi-spinner loading"></i>
        <img [src]="avatar" *ngIf="avatar">
      </div>
      <input style="display: none" type="file" (change)="uploaderAva($event); fileInput.value = ''" #fileInput accept="image/*">
      <button pButton (click)="fileInput.click()" [disabled]="loadingAva">Change Avatar</button>
      <button *ngIf="avatar" pButton icon="pi pi-trash" (click)="deleteAvatar()" class="p-ml-2"></button>
    </div>

  </div>


</p-card>
