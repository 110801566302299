import {Component, OnDestroy, OnInit} from '@angular/core';
import {Message} from 'primeng/api';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth/auth.service';
import {AccountSettingsService} from './account-settings.service';
import {CognitoUserAttributes} from '../auth/auth';
import {LabelPageService} from '../base/topbar/app.label-page.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  public loading = false;
  public messagesValue: Message[] = [];
  public form: FormGroup;
  public avatar: string|undefined;
  public loadingAva = false;
  public title = 'Account settings';

  constructor(
    private lps: LabelPageService,
    private fb: FormBuilder,
    private auth: AuthService,
    private profile: AccountSettingsService
  ) {
    this.lps.setData({label: this.title}, this.title);

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      roleDescription: ['']
    });

    this.subscription = this.auth.currentUserSubject.subscribe((currentUser) => {
      if (currentUser) {
        this.form.setValue({
          name: currentUser.name,
          company: currentUser.company,
          roleDescription: currentUser.roleDescription || ''
        });
        this.avatar = currentUser.picture;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.messagesValue = [];
      this.loading = true;
      const value = this.form.value;
      const attributes: CognitoUserAttributes = {
        'custom:company': value.company,
        'custom:role_description': value.roleDescription,
        name: value.name
      };
      this.profile.updateProfile(attributes).subscribe(() => {
        this.messagesValue = [{severity: 'success', summary: 'Success', detail: 'Updated profile'}];
        this.loading = false;
      }, error => {
        this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
        this.loading = false;
      });
    }
  }

  uploaderAva(event: any): void {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      this.loadingAva = true;
      this.profile.changeAvatar(selectedFile).subscribe(data => {
          this.loadingAva = false;
          if (data.success) {
            this.auth.cognitoUserToCurrentUser();
          }
        }, (error => {
          this.loadingAva = false;
        })
      );
    }
  }
  deleteAvatar(): void {
    this.loadingAva = true;
    this.profile.deleteAvatar().subscribe(
      data => {
        this.loadingAva = false;
        if (data.success) {
          this.auth.cognitoUserToCurrentUser();
        }
      }, (error => {
        this.loadingAva = false;
      })
    );
  }
}
