import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-confirm-sign-up',
  templateUrl: './confirm-sign-up.component.html',
  styleUrls: ['./confirm-sign-up.component.scss']
})
export class ConfirmSignUpComponent implements OnInit {
  public loading = false;
  public loadingResent = false;
  public title = 'Verify Email';
  public messagesValue: Message[] = [];
  public form: FormGroup = this.fb.group({
    code: ['', [Validators.required]]
  });
  public email = this.authService.confirmEmail;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.title + ' | ' + environment.nameSite);
  }

  ngOnInit(): void {
    if (!this.email) {
      this.router.navigate(['/sign-in']).then();
    }
  }
  onSubmit(): void {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.authService.confirmSignUp(this.email, value.code).subscribe(
        () => {
          this.router.navigate(['/request-received']).then();
          this.loading = false;
        },
        (error => {
          this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
          this.loading = false;
        })
      );
    }
  }

  resent(): void {
    this.loadingResent = true;
    this.messagesValue = [];
    this.authService.resendConfirmationCode(this.email).subscribe(
      () => {
        this.loadingResent = false;
        this.messagesValue = [{severity: 'success', summary: 'Success', detail: 'Verification code sent to your email'}];
      },
      (error => {
        this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
        this.loadingResent = false;
      })
    );
  }
}
