import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataChart} from '../charts';

@Component({
  selector: 'app-circle-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['./circle-chart.component.scss']
})

export class CircleChartComponent  extends AmChartsComponent{
  @Input() data: DataChart[] = [];

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any, am4pluginsForceDirected: any): void {

    am4core.useTheme(am4themesAnimated);
    const chart = am4core.create(this.idChart, am4pluginsForceDirected.ForceDirectedTree);
    const networkSeries = chart.series.push(new am4pluginsForceDirected.ForceDirectedSeries());

    const data = this.data;
    data.sort((a, b) => {
      return b.value - a.value; });

    networkSeries.data = data;

    networkSeries.dataFields.linkWith = 'linkWith';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'name';
    networkSeries.dataFields.value = 'value';
    networkSeries.links.template.distance = 1;
    networkSeries.nodes.template.tooltipText = '{name} ({value})';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.outerCircle.scale = 1;

    networkSeries.nodes.template.label.text = '{name}';
    networkSeries.fontSize = this.fontSize;
    networkSeries.nodes.template.label.hideOversized = true;
    // networkSeries.nodes.template.label.truncate = true;

    networkSeries.minRadius = am4core.percent(2);
    networkSeries.manyBodyStrength = -5;
    networkSeries.links.template.strokeOpacity = 0;

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    chart.legend.fontSize = this.fontSize;


    chart.legend.useDefaultMarker = true;
    const marker = chart.legend.markers.template;
    const markerColumn = marker.children.getIndex(0);

    markerColumn.cornerRadius(0, 0, 0, 0);
    markerColumn.defaultState.properties.fillOpacity = 0;
    markerColumn.defaultState.properties.strokeWidth = 1;
    markerColumn.defaultState.properties.strokeOpacity = 1;

    networkSeries.nodes.template.events.on('toggled', (ev: any) => {
      if (!ev.target.isActive) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    });

    this.chart = chart;
  }
}
