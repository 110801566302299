import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ViewerInitializedEvent, ViewerOptions} from 'ng2-adsk-forge-viewer';
import {ModelViewService} from './model-view.service';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {ForgeModel} from './model-view';

@Component({
  selector: 'app-model-view',
  templateUrl: './model-view.component.html',
  styleUrls: ['./model-view.component.scss']
})
export class ModelViewComponent implements OnDestroy {
  private subSlug: Subscription;
  public viewerOptions3d: ViewerOptions|null = null;
  public title = '';
  public titleNoSelectModel = 'No Model Selected';
  public model: ForgeModel|null = null;
  public isNoSelectModel = false;

  constructor(
    private route: ActivatedRoute,
    private mvs: ModelViewService,
    private lps: LabelPageService
  ) {
    this.subSlug = this.route.params.subscribe(routeParams => {
      this.init(routeParams.slug);
    });
  }

  ngOnDestroy(): void {
    this.subSlug.unsubscribe();
  }

  init(slug?: string): void {
    this.viewerOptions3d = null;
    this.isNoSelectModel = false;
    this.mvs.getAllModels().subscribe(data => {
      const selectPages = data.map(item => {
        return {
          name: item.title,
          routerLink: ['/model-view', item.slug || '']
        };
      });
      this.model = data.find(item => slug === item.slug) || null;
      if (this.model) {
        const title = this.title + ' ' + this.model.title;
        this.lps.setData({label: title, selectPages, activeRouterLink: ['/model-view', this.model.slug || '']}, title);
        this.mvs.getLastVersionUrn(this.model.urn).subscribe(urnBase64 => {
          this.initForgeViewer(urnBase64);
        });
      }
      else {
        selectPages.unshift({name: 'Select the model', routerLink: ['/model-view']});
        this.lps.setData({label: this.titleNoSelectModel, selectPages, activeRouterLink: ['/model-view']}, this.titleNoSelectModel);
        this.isNoSelectModel = true;
      }
    });
  }
  initForgeViewer(urn?: string): void {
    if (urn) {
      this.viewerOptions3d = {
        initializerOptions: {
          env: 'AutodeskProduction',
          language: 'en',
          getAccessToken: (onGetAccessToken: (token: string, expire: number) => void) => {
            this.mvs.getAccessToken().subscribe(data => {
              onGetAccessToken(data.token, data.expireTime);
            });
          },
          api: 'derivativeV2',
        },
        onViewerInitialized: (args: ViewerInitializedEvent) => {
          args.viewerComponent.DocumentId = urn;
        },
      };
    }
  }

}
