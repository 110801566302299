import {Component, OnInit} from '@angular/core';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {SettingsSiteService} from '../galaxy/settings-site.service';
import {FilterMetadata, LazyLoadEvent} from 'primeng/api';
import {columnsIssue, FiltersVariants, Issue} from './issues';
import {IssuesService} from './issues.service';
import {FieldAndHeader, GqlFilter} from '../galaxy/g';
import {Table} from 'primeng/table';
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {
  public issues: Issue[] = [];
  public loading = true;
  public title = 'Issue Log';
  public totalCount = 0;
  public pageSize = this.setting.getPageSize();
  public pageSizeOptions = this.setting.PAGE_SIZE_VARIANT;
  public columns = columnsIssue;
  private sc = this.setting.getFieldsTableIssues();
  private tmpSelectedColumns: FieldAndHeader[] = this.columns.filter(item => this.sc.includes(item.field));
  public filtersVariants: FiltersVariants = {};
  public filterFieldsVariantSelect = [
    'type', 'status', 'author', 'priority', 'zone', 'phase', 'group',
    'lastModificationAuthor', 'assignedTo', 'creationSource', 'disciplines',
    'uniformats', 'teams'
  ];
  public filterFieldsVariantDate = [
    'dueDate', 'issueCreated', 'closingDate', 'lastModificationDate'
  ];
  public fieldsHiddenSort = ['disciplines', 'teams', 'uniformats', 'description'];
  private lastEventStringify = '';
  private isInit = true;

  constructor(
    private lps: LabelPageService,
    private issS: IssuesService,
    private setting: SettingsSiteService
  ) {
    this.lps.setData({label: this.title}, this.title);
    this.issS.getFilters(this.filterFieldsVariantSelect.map(item => {
      const itemCI = columnsIssue.find(dd => dd.field === item);
      return itemCI?.suggestionsName || item;
    }))
      .subscribe(data => this.filtersVariants = data);
  }

  ngOnInit(): void {
  }

  filter(event: LazyLoadEvent, dt: Table): void {
    if (this.isInit) {
      dt.filters.status = [{value: 'Open', matchMode: 'equals', operator: 'and'}];
      this.isInit = false;
    }
    const eventStringify = JSON.stringify(event);
    if (this.lastEventStringify === eventStringify) {
      return;
    }
    this.lastEventStringify = eventStringify;

    this.loading = true;
    const filters: GqlFilter[] = [];
    if (event.filters) {
      Object.entries(event.filters).forEach(([field, meta]) => {
        const metaFi = meta as FilterMetadata[];
        if (field !== 'global' && metaFi[0].value !== null) {
          const colItem = columnsIssue.find(item => item.field === field);
          if (colItem) {
            const nameF = colItem.filter || colItem.field;
            if (this.filterFieldsVariantDate.includes(field)) {
              filters.push({field: nameF, value: formatDate(metaFi[0].value, 'YYYY-MM-dd', 'en')});
            }
            else {
              filters.push({field: nameF, value: metaFi[0].value});
            }
          }
        }
      });
    }
    this.issS.filterIssues({
      first: event.rows,
      offset: event.first,
      search: event.globalFilter || undefined,
      order: event.sortField ? [{field: event.sortField, direction: (event.sortOrder === -1 ? 'DESC' : 'ASC')}] : undefined,
      filter: filters
    }).subscribe(data => {
      this.issues = data.edges.map(item => item.node);
      this.totalCount = data.totalCount;
      this.loading = false;
    }, () => {this.loading = false; });
  }

  get selectedColumns(): FieldAndHeader[] {
    return this.tmpSelectedColumns;
  }

  set selectedColumns(val: FieldAndHeader[]) {
    const cols = this.columns.filter(item => val.includes(item));
    this.setting.setFieldsTableIssues(cols.map(item => item.field));
    this.tmpSelectedColumns = cols;
  }


  clearTable(table: Table, globalFilter: any): void {
    globalFilter.value = null;
    table.filters.global = {
      value: null,
      matchMode: 'contains'
    };
    table.clear();
  }

}
