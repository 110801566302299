import { Zone } from '../issues/issues';

export interface IssueByPriority {
  priority: string;
  number: number;
}

export interface DashboardCounters {
  openIssues: number;
  cleanTimeAvg: number;
  criticalIssues: number;
  resolvedPercentage: number;
  resolvedCount: number;
  coordinationComplete: number;
}

export interface LateStatus {
  name: string;
  number: number;
}

export interface IssuesByZoneAndDeadline {
  zone: Zone;
  aggregations: [LateStatus];
}

export interface IssueByTeamResponsibleAndDeadline {
  team: string;
  aggregations: [LateStatus];
}

export interface IssueByType {
  type: string;
  number: number;
}

export interface IssueByTeam {
  team: string;
  number: number;
}

export interface IssueByZone {
  zone: Zone;
  number: number;
}

export interface IssueByStatus {
  status: string;
  number: number;
}

export interface MilestoneTimeline {
  pointType: string;
  number: number;
  name: string;
  completed: number;
  date: string;
  late: number;
  lateGroup: string;
}

export interface OverallMilestoneTimeline {
  zone: Zone;
  milestones: MilestoneTimeline[];
}

export interface DashboardNamespace {
  charts: {
    issuesByZoneAndDeadline: IssuesByZoneAndDeadline[];
    issuesByTeamAndDeadline: IssueByTeamResponsibleAndDeadline[];
    issuesByType: IssueByType[];
    criticalIssuesByTeam: IssueByTeam[];
    criticalIssuesByZone: IssueByZone[];
    issuesByStatus: IssueByStatus[];
    overallCoordinationTimeline: OverallMilestoneTimeline[];
  };
  counters: DashboardCounters;
}

export const GQLDashboardNamespace = `
  query q {
    user {
      dashboard {
        charts {
          issuesByZoneAndDeadline {zone {id name color} aggregations {name number}}
          issuesByTeamAndDeadline {team aggregations {name number}}
          issuesByType {type number}
          criticalIssuesByTeam {team number}
          criticalIssuesByZone {zone {id name color} number}
          issuesByStatus {status number}
          overallCoordinationTimeline {
            zone {id name}
            milestones {pointType number name completed date late lateGroup}
          }
        }
        counters {
          openIssues
          cleanTimeAvg
          criticalIssues
          resolvedPercentage
          resolvedCount
          coordinationComplete
        }
      }
    }
  }
`;

export interface HealthPoint {
  total: number;
  closed: number;
  dateStart: string;
  dateEnd: string;
  period: string;
}

export const GQLDashboardOverallBimModelHealth = `
  query q($period: String, $startDate: Date, $endDate: Date) {
    user {
      dashboard {
        charts {
          overallBimModelHealth(period: $period, startDate: $startDate, endDate: $endDate) {
            total
            closed
            dateStart
            dateEnd
            period
          }
        }
      }
    }
  }
`;

export const periodicityOverallBimModelHealth = [
  {label: 'Daily', value: 'day'},
  {label: 'Weekly', value: 'week'},
  {label: 'Monthly', value: 'month'}
];

