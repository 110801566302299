import {Component, Input, OnInit} from '@angular/core';
import {priorityColor} from '../issues';

@Component({
  selector: 'app-priority-issue',
  templateUrl: './priority-issue.component.html',
  styleUrls: ['./priority-issue.component.scss']
})
export class PriorityIssueComponent implements OnInit {
  @Input() name!: string;
  public color?: string;

  constructor() {
  }

  ngOnInit(): void {
    this.color = priorityColor.find(item => item.name === this.name)?.color || priorityColor[0].color;
  }
}
