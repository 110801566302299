import {Component, Input, OnInit} from '@angular/core';
import {IssuesService} from '../issues.service';
import {FilesService} from '../../files/files.service';

@Component({
  selector: 'app-export-issues',
  templateUrl: './export-issues.component.html',
  styleUrls: ['./export-issues.component.scss']
})
export class ExportIssuesComponent implements OnInit {
  @Input() label = 'Export';
  @Input() title = 'XLS';
  @Input() icon = 'pi pi-file-excel';
  @Input() idLevel?: string;

  constructor(
    private issS: IssuesService,
    private fs: FilesService
  ) { }

  ngOnInit(): void {
  }

  exportCSV(): void {
    this.issS.exportCSV(this.idLevel).subscribe(
      data => {
        this.fs.download(data.result.publicUrl, data.result.filename, false);
      }
    );
  }
}
