import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GHttpService} from '../galaxy/ghttp.service';
import {GQLPlanFile, GQLScheduleFile} from './pdf-page';
import {PDFDocument} from '../files/files';
import {GqlPagination} from '../galaxy/g';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdfPageService {

  constructor(
    private http: GHttpService
  ) { }

  getScheduleList(): Observable<PDFDocument[]> {
    return this.http.graphql<GqlPagination<PDFDocument>>(GQLScheduleFile, ['user', 'bimDocuments', 'coordinationScheduleList']).pipe(
      map(data => {
        return data.edges.map(item => item.node);
      })
    );
  }

  getPlanList(): Observable<PDFDocument[]> {
    return this.http.graphql<GqlPagination<PDFDocument>>(GQLPlanFile, ['user', 'bimDocuments', 'executionPlanList']).pipe(
      map(data => {
        return data.edges.map(item => item.node);
      })
    );
  }
}
