<div class="p-grid">
  <div class="p-md-6" *ngFor="let item of contacts">
    <p-card>
      <div class="p-d-flex">
        <p-avatar *ngIf="item.picture" [image]="item.picture" styleClass="p-mr-4 ava" shape="circle"></p-avatar>
        <p-avatar *ngIf="!item.picture" [label]="getFIO(item.name)" styleClass="p-mr-4 ava" shape="circle"></p-avatar>
        <div>
          <div class="p-mb-2"><b>{{item.name}}</b></div>
          <div class="p-mb-2">{{item.email}}</div>
          <div class="p-mb-2">{{item.company}}</div>
          <div class="p-mb-2">{{item.roleDescription}}</div>
        </div>
      </div>
    </p-card>
  </div>
</div>
