import {
  IssueByPriority,
  IssueByTeam,
  IssueByTeamResponsibleAndDeadline,
  IssueByZone,
  IssuesByZoneAndDeadline,
  MilestoneTimeline
} from '../dashboard/dashboard';
import {ObjectIdName} from '../galaxy/g';

export interface IssueByType {
  type: string;
  number: number;
}
export interface IssuesByGroup {
  group: string;
  number: number;
}
export interface IssueByStatus {
  status: string;
  number: number;
}

export interface LevelCounters {
  openIssues: number;
  cleanTimeAvg: number;
  criticalIssues: number;
  resolvedPercentage: number;
  resolvedCount: number;
  coordinationComplete: number;
}

export interface LevelAnalysisNamespace {
  charts: {
    issuesByType: IssueByType[];
    issuesByPriority: IssueByPriority[];
    issuesByStatus: IssueByStatus[];

    coordinationTimeline: MilestoneTimeline[];
    issuesByTeamAndDeadline: IssueByTeamResponsibleAndDeadline[];
    criticalIssuesByTeam: IssueByTeam[];
    issuesByZoneAndDeadline: IssuesByZoneAndDeadline[];
  };
  counters: LevelCounters;
}

export const GQLLevelAnalysisNamespace = `
  query q($id: ID!) {
    user {
      levelAnalysis(id: $id) {
        charts {
          issuesByType {type number}
          issuesByPriority {priority number}
          issuesByStatus {status number}

          coordinationTimeline {pointType number name completed date late lateGroup}
          criticalIssuesByTeam {team number}
          issuesByTeamAndDeadline {team aggregations {name number}}
          issuesByZoneAndDeadline {zone {id name color} aggregations {name number}}
        }
        counters {
          openIssues
          cleanTimeAvg
          criticalIssues
          resolvedPercentage
          resolvedCount
          coordinationComplete
        }
      }
    }
  }
`;

export interface Level extends ObjectIdName {
  slug: string;
}

export const GQLAllLevels = `
  query q($after: String) {
    user {
      issueLog {
        zones(after: $after) {
          edges {
            node {id name}
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const GQLLevelAnalysisOverallBimModelHealth = `
  query q($id: ID!, $period: String, $startDate: Date, $endDate: Date) {
    user {
      levelAnalysis(id: $id) {
        charts {
          bimModelHealth(period: $period, startDate: $startDate, endDate: $endDate) {
            total
            closed
            dateStart
            dateEnd
            period
          }
        }
      }
    }
  }
`;
