import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {GHttpService} from './ghttp.service';
import {map, tap} from 'rxjs/operators';

type TypeColor = 'zone'|'issueStatus'|'uniformat'|'issueType'|'issuePriority'|'issuePhase'|'lateColor';

interface DataTypeColor {
  scope: string;
  colors: ColorValue[];
}
interface ColorValue {
  name: string;
  value: string|null;
}

const GQLColors = `
  query q {
    user {
      colors {
        scope
        colors {
          name
          value
        }
      }
    }
  }
`;

export const colorVT = {
  red: '#c9242c',
  blue: '#00a8f5'
};
export const scopeVTColor = {
  scope: 'lateColor',
  colors: [
    {
      name: 'Late 7+ Days',
      value: colorVT.red
    },
    {
      name: 'Late 1-6 Days',
      value: '#FFC519'
    },
    {
      name: 'On Time',
      value: colorVT.blue
    },
    {
      name: 'Completed',
      value: '#8FC550'
    },
    {
      name: 'Not Started',
      value: '#cccccc'
    }
  ]
};

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private data: DataTypeColor[] = [];
  public defaultColor = '#000000';

  constructor(
    private http: GHttpService
  ) { }

  private getTypeColorsFromServer(): Observable<DataTypeColor[]> {
    return this.http.graphql<DataTypeColor[]>(GQLColors, ['user', 'colors']).pipe(
      map(data => {
        data.push(scopeVTColor);
        return data;
      }),
      tap(data => this.data = data)
    );
  }

  getDataTypeColors(): Observable<DataTypeColor[]> {
    return this.data.length ? of(this.data) : this.getTypeColorsFromServer();
  }

  getTypeColors(type: TypeColor): ColorValue[] {
    return this.data.find(item => item.scope === type)?.colors || [];
  }

  getColor(type: TypeColor, key: string): string {
    return this.getTypeColors(type).find(item => item.name === key)?.value || this.defaultColor;
  }
}
