export const environment = {
  production: true,
  mandatorySignIn: true,
  region: 'us-east-1',
  userPoolId: 'us-east-1_2P7vOON9f',
  userPoolWebClientId: '1vmn91lle03bvdkfda53et48so',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  nameSite: 'Galaxy',
  graphQLServer: 'https://api22384.galaxy.viatechnik-inc.com/'
};
