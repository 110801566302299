import {FieldAndHeader, GQLSuccessResult, ObjectIdName} from '../galaxy/g';
import {UploadedFile, uploadedFileSegment} from '../files/files';

export interface Issue {
  number: number;
  title: string;
  status: string;
  type: string;
  priority: string;
  zone: ObjectIdName;
  phase: string;
  assignedTo: string;
  dueDate: string;
  group: string;
  author: string;
  issueCreated: string;
  closingDate: string;
  description: string;
  lastModificationAuthor: string;
  lastModificationDate: string;
  creationSource: string;
  disciplines: string[];
  uniformats: string[];
  teams: string[];
}

export const columnsIssue: FieldAndHeader[] =  [
  { field: 'number', header: 'Issue Number', inactive: true},
  { field: 'title', header: 'Title' },
  { field: 'description', header: 'Description' },
  { field: 'status', header: 'Status' },
  { field: 'type', header: 'Type' },
  { field: 'priority', header: 'Priority' },
  { field: 'disciplines', header: 'Disciplines'},
  { field: 'zone', header: 'Level', filter: 'zoneId' },
  { field: 'phase', header: 'Phase' },
  { field: 'assignedTo', header: 'Assigned to' },
  { field: 'dueDate', header: 'Due date' },
  { field: 'group', header: 'Issue Group' },
  { field: 'teams', header: 'Teams'},
  { field: 'uniformats', header: 'Uniformats'},
  { field: 'creationSource', header: 'Creation Source'},
  { field: 'author', header: 'Author' },
  { field: 'issueCreated', header: 'Created (UTC)' },
  { field: 'lastModificationDate', header: 'Last Modification Date'},
  { field: 'lastModificationAuthor', header: 'Last Modification Author'},
  { field: 'closingDate', header: 'Closing Date (UTC)' }
];

const issueSegment = `
  number
  title
  status
  type
  priority
  zone {id name}
  phase
  assignedTo
  dueDate
  group
  author
  issueCreated
  closingDate
  description
  lastModificationAuthor
  lastModificationDate
  creationSource
  disciplines
  uniformats
  teams
`;

export const GQLAllIssues = `
  query q($after: String, $filter: [Filter]) {
    user {
      issueLog {
        issues(after: $after, filter: $filter) {
          edges {
            node {
              ` + issueSegment + `
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const GQLFilterIssues = `
  query q($offset: Int, $first: Int, $filter: [Filter], $search: String, $order: [Ordering]) {
    user {
      issueLog {
        issues(offset: $offset, first: $first, filter: $filter, search: $search, order: $order) {
          edges {
            node {
              ` + issueSegment + `
            }
          }
          totalCount
        }
      }
    }
  }
`;

export function GQLFiltersIssue(fields: string[]): string {
  const suggestions = fields.map(field => {
    return `
    ${field}: suggestions(name: "${field}") {
      id
      value
    }
    `;
  });
  return `
    query q {
      user {
        issueLog {
          ${suggestions.join()}
        }
      }
    }`;
}

export interface FiltersVariants {
  [key: string]: {id?: string; value: string}[];
}

export interface ExportCsvMutationInput {
  zone: string;
}

export interface ExportCsvMutationPayload extends GQLSuccessResult{
  result: UploadedFile;
}

export const GQLExportCsv = `
  mutation m($input: ExportCsvMutationInput!) {
    user {
      issueLog{
        exportCsv(input: $input) {
          success
          result {
            ${uploadedFileSegment}
          }
        }
      }
    }
  }
`;

export const priorityColor = [
  {
    color: '#9F9F9F',
    name: '[Not set]',
  },
  {
    color: '#DD4D40',
    name: 'Critical',
  },
  {
    color: '#FFC519',
    name: 'High',
  },
  {
    color: '#8FC550',
    name: 'Medium',
  },
  {
    color: '#4688F4',
    name: 'Low',
  }
];

export interface Zone {
  id: string;
  name: string;
  color: string;
}
