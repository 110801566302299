import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AmChartsComponent} from './am-charts.component';
import { CircleChartComponent } from './circle-chart/circle-chart.component';
import { CategoryChartComponent } from './category-chart/category-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { GalaxyTimelineChartComponent } from './galaxy-timeline-chart/galaxy-timeline-chart.component';
import { LinesChartComponent } from './lines-chart/lines-chart.component';
import { GalaxyTimelineByLevelComponent } from './galaxy-timeline-by-level/galaxy-timeline-by-level.component';
import { GalaxyProgressByLevelComponent } from './galaxy-progress-by-level/galaxy-progress-by-level.component';



@NgModule({
  declarations: [
    AmChartsComponent,
    CircleChartComponent,
    CategoryChartComponent,
    DonutChartComponent,
    GalaxyTimelineChartComponent,
    LinesChartComponent,
    GalaxyTimelineByLevelComponent,
    GalaxyProgressByLevelComponent
  ],
  exports: [
    CircleChartComponent,
    CategoryChartComponent,
    DonutChartComponent,
    GalaxyTimelineChartComponent,
    LinesChartComponent,
    GalaxyTimelineByLevelComponent,
    GalaxyProgressByLevelComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ChartsModule { }
