import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public loading = false;
  public title = 'Forget Password';
  public messagesValue: Message[] = [];
  public form: FormGroup = this.fb.group({
    email: ['', [Validators.email]]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Reset Password to Your Account | ' + environment.nameSite);
  }

  ngOnInit(): void {}

  onSubmit(): void {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.authService.forgotPassword(value.email).subscribe(
        () => {
          this.authService.confirmEmail = value.email;
          this.router.navigate(['/confirm-reset-password']).then();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
        }
      );

    }
  }
}
