import { Injectable } from '@angular/core';
import {GqlInputFilter, GqlInputPOF, GqlPagination} from '../galaxy/g';
import {Observable} from 'rxjs';
import {
  ExportCsvMutationInput,
  ExportCsvMutationPayload,
  FiltersVariants,
  GQLAllIssues,
  GQLExportCsv,
  GQLFilterIssues,
  GQLFiltersIssue,
  Issue
} from './issues';
import {GHttpService} from '../galaxy/ghttp.service';
import {map} from 'rxjs/operators';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {
  constructor(
    private http: GHttpService
  ) { }

  filterIssues(input: GqlInputPOF): Observable<GqlPagination<Issue>> {
    return this.http.graphql(GQLFilterIssues, ['user', 'issueLog', 'issues'], input);
  }

  getAllIssues(input: GqlInputFilter = {}): Observable<Issue[]> {
    return this.http.graphqlGetFull<Issue>(GQLAllIssues, {input}, ['user', 'issueLog', 'issues']);
  }

  getFilters(fields: string[]): Observable<FiltersVariants> {
    return this.http.graphql<FiltersVariants>(GQLFiltersIssue(fields), ['user', 'issueLog']).pipe(
      map(data => {
        const result: FiltersVariants = {};
        Object.entries(data).forEach(([field, variants]) => {
          switch (field) {
            // case 'dueDate':
            // case 'closingDate':
            // case 'issueCreated':
            // case 'lastModificationDate':
            //   result[field] = variants.filter(item => !!item).map(item => formatDate(item, 'shortDate', 'en'));
            //   break;
            default:
              result[field] = variants.filter(item => !!item.value);
          }
        });
        return result;
      })
    );
  }

  exportCSV(idLevel?: string): Observable<ExportCsvMutationPayload> {
    const input: ExportCsvMutationInput|{} = idLevel ? {
      zone: idLevel
    } : {};
    return this.http.graphql(GQLExportCsv, ['user', 'issueLog', 'exportCsv'], {input});
  }
}
