export const GQLAccessToken = `
  query q {
    user {
      forge {
        accessToken
      }
    }
  }
`;

export interface ForgeModel {
  filename: string;
  title: string;
  urn: string;
  slug?: string;
}

export const GQLForgeModel = `
  query q($after: String) {
    user {
      forge {
        models(after: $after) {
          edges {
            node {
              title
              filename
              urn
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const GQLLastVersionURNBase64 = `
  query q($urn: String!) {
    user {
      forge {
        lastVersion(urn: $urn)
      }
    }
  }
`;

export interface AccessToken {
  token: string;
  expireTime: number;
}
