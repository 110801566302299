import { NgModule } from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {MessagesModule} from 'primeng/messages';
import {AvatarModule} from 'primeng/avatar';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {BadgeModule} from 'primeng/badge';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SliderModule} from 'primeng/slider';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DialogModule} from 'primeng/dialog';



@NgModule({
  declarations: [],
  imports: [],
  exports: [
    ButtonModule,
    InputTextModule,
    PasswordModule,
    MessagesModule,
    AvatarModule,
    CardModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    BadgeModule,
    CheckboxModule,
    ConfirmDialogModule,
    SliderModule,
    CalendarModule,
    HttpClientModule,
    FileUploadModule,
    InputTextareaModule,
    DialogModule
  ],
  providers: []
})
export class PrimeModule { }
