<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appBase.onMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
        </a>
        <div *ngIf="labelPage" class="p-d-flex p-ai-center">
          <a class="p-mr-3 p-button-outlined p-button-secondary" *ngIf="labelPage.backRouterLink" [routerLink]="labelPage.backRouterLink" pButton icon="pi pi-chevron-left" ></a>
          <span class="viewname" style="text-transform: uppercase">{{labelPage.label}}</span>
        </div>

<!--        <img id="logo-mobile" class="mobile-logo" [src]="'assets/images/logo-d-black.png'" alt="diamond-layout" />-->
    </div>

    <div class="topbar-right p-d-flex p-ai-center">
        <p-dropdown *ngIf="labelPage?.selectPages"
                    [options]="labelPage?.selectPages || []"
                    optionLabel="name"
                    optionValue="routerLink"
                    [ngModel]="labelPage?.activeRouterLink"
                    (onChange)="navigate($event)"
        ></p-dropdown>
        <ul class="topbar-menu">
            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appBase.topbarUserMenuActive}">
                <a href="#" (click)="appBase.onTopbarUserMenuButtonClick($event)">
                  <p-avatar *ngIf="currentUser?.picture" [image]="currentUser?.picture || ''" styleClass="profile-image" shape="circle"></p-avatar>
                  <p-avatar *ngIf="!currentUser?.picture" icon="pi pi-user" styleClass="profile-image" shape="circle"></p-avatar>
                  <span class="profile-name">{{currentUser?.name}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li>
                        <a [routerLink]="['/account-settings']">
                            <i class="pi pi-cog"></i>
                            <span>Account settings</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="signOut()">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
