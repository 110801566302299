import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {GalaxyWeather, GQLWeather} from './weather';
import {GHttpService} from '../galaxy/ghttp.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  public galaxyWeather = new BehaviorSubject<GalaxyWeather|null>(null);
  private noaaToFont: {[key: string]: string} = {
    bkn: '&#75;', nbkn: '&#76;',
    skc: '&#33;', nskc: '&#40;',
    few: '&#34;', nfew: '&#73;',
    sct: '&#34;', nsct: '&#73;',
    ovc: '&#36;', novc: '&#74;',
    fg: '&#39;', nfg: '&#96;',
    smoke: '&#87;',
    fzra: '&#82;',
    ip: '&#55;',
    mix: '&#121;', nmix: '&#122;',
    raip: '&#54;',
    rasn: '&#53;', nrasn: '&#69;',
    shra: '&#42;',
    tsra: '&#56;', ntsra: '&#72;',
    sn: '&#52;', nsn: '&#68;',
    wind: '&#92;', nwind: '&#110;',
    hi_shwrs: '&#42;', hi_nshwrs: '&#58;',
    fzrara: '&#82;',
    hi_tsra: '&#56;', hi_ntsra: '&#72;',
    ra1: '&#49;',
    ra: '&#50;', nra: '&#66;',
    nsvrtsra: '&#111;',
    dust: '&#88;',
    mist: '&#38;'
  };

  constructor(
    private http: GHttpService
  ) {
    this.intervalGetWeatherForecast().subscribe(data => {
      this.galaxyWeather.next(data);
    });
  }

  getWeatherForecast(): Observable<GalaxyWeather> {
    return this.http.graphql<GalaxyWeather>(GQLWeather, ['user', 'weather']).pipe(
      map(data => {
        const noaa = data.icon.split('.')[0];
        return {
          ...data,
          icon: this.noaaToFont[noaa] || '&#124;',
        };
      })
    );
  }

  private intervalGetWeatherForecast(): Observable<GalaxyWeather> {
    return timer(0, 60 * 60 * 1000).pipe(
      switchMap(() => {
        return this.getWeatherForecast();
      })
    );
  }

}
