import { Component, OnInit } from '@angular/core';
import {MenuService} from './menu/app.menu.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  private userMenuClick = false;
  public topbarUserMenuActive = false;
  public overlayMenuActive = false;
  public staticMenuMobileActive = false;
  private menuClick = false;
  public staticMenuDesktopInactive = false;
  private resetMenu = false;
  public menuHoverActive = false;

  constructor(
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
  }

  hideOverlayMenu(): void {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  onTopbarUserMenuButtonClick(event: any): void {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onMenuButtonClick(event: any): void {
    this.menuClick = true;
    this.topbarUserMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  isOverlay(): boolean {
    return false; // this.app.menuMode === 'overlay';
  }

  isDesktop(): boolean {
    return window.innerWidth > 991;
  }

  isMobile(): boolean {
    return window.innerWidth <= 991;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  onMenuClick(): void {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onLayoutClick(): void {

    if (!this.userMenuClick) {
      this.topbarUserMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isSlim()) {
        this.menuService.reset();
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.userMenuClick = false;
    this.menuClick = false;
  }

  isSlim(): boolean {
    return false;
  }

}
