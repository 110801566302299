import {Component, Input} from '@angular/core';
import {AmChartsComponent} from '../am-charts.component';
import {DataTimelineByLevel} from '../charts';
import {scopeVTColor} from '../../galaxy/color.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-galaxy-timeline-by-level',
  templateUrl: '../chart.component.html',
  styleUrls: ['./galaxy-timeline-by-level.component.scss']
})
export class GalaxyTimelineByLevelComponent extends AmChartsComponent {
  @Input() data: DataTimelineByLevel[] = [];

  initChart(am4core: any, am4charts: any, am4themesAnimated: any, am4themesDataviz: any): void {
    if (!this.data.length) {
      this.empty();
      return;
    }

    am4core.useTheme(am4themesAnimated);
    const chart = am4core.create(this.idChart, am4charts.XYChart);

    const legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.data = scopeVTColor.colors.slice().reverse().map(item => {
      return {
        name: item.name,
        fill: item.value
      };
    });
    legend.fontSize = this.fontSize;
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    // Add data
    chart.data = this.data;
    const currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);

    const xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.dataFields.value = 'date';
    xAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    xAxis.renderer.labels.template.fontSize = this.fontSize;
    xAxis.baseInterval = {
      timeUnit: 'day',
      count: 1
    };

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'level';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.textColor);
    // smaller because so much values
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 10;


    const range = xAxis.axisRanges.create();
    range.value = currentDate;
    range.grid.stroke = am4core.color('#000000');
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 0.8;
    range.label.fill = range.grid.stroke;
    range.label.text = 'Today';
    range.label.dy = -10;

    // Create series
    this.data.forEach(item => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.data = [
        {date: new Date(item.startDate), level: item.level},
        {date: new Date(item.endDate), level: item.level}
      ];
      series.dataFields.dateX = 'date';
      series.dataFields.categoryY = 'level';
      series.stroke = am4core.color(item.color);
      series.strokeWidth = 10;

      series.segments.template.interactionsEnabled = true;
      series.tooltipPosition = 'pointer';

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(item.color);
      series.tooltipText = item.level + '\n' + item.progress + '% Complete\nStart date: ' +
        formatDate(item.startDate, 'MM/dd/YYYY', 'en') +
        '\n Finish date: ' + formatDate(item.endDate, 'MM/dd/YYYY', 'en');
    });

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.data = [];
    this.data.forEach(item => {
      if (item.currentDateColor) {
        series2.data.push(
          {
            date: currentDate,
            currentDateColor: am4core.color(item.currentDateColor),
            level: item.level,
            progress: item.progress,
            startDate: formatDate(item.startDate, 'MM/dd/YYYY', 'en'),
            endDate: formatDate(item.endDate, 'MM/dd/YYYY', 'en')
          }
        );
      }
    });
    series2.dataFields.dateX = 'date';
    series2.dataFields.categoryY = 'level';
    series2.strokeWidth = 0;

    const bullet = series2.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = '{level}\n{progress}% Complete\nStart date: {startDate}\n Finish date: {endDate}';
    bullet.propertyFields.fill = 'currentDateColor';
    const square = bullet.createChild(am4core.Rectangle);
    square.horizontalCenter = 'middle';
    square.verticalCenter = 'middle';
    square.width = 10;
    square.height = 9;
    square.propertyFields.stroke = 'currentDateColor';
    square.propertyFields.fill = 'currentDateColor';

    // Add scrollbar
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();

    this.chart = chart;
  }
}
