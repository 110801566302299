import { Component } from '@angular/core';
import {LabelPageService} from '../base/topbar/app.label-page.service';
import {AnnouncementsService} from './announcements.service';
import {Announcement} from './announcements';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent {
  public announcements: Announcement[] = [];
  public loading = true;
  public title = 'Announcements';


  constructor(
    private lps: LabelPageService,
    private ans: AnnouncementsService
  ) {
    this.lps.setData({label: this.title}, this.title);

    this.ans.getAllAnnouncements().subscribe(data => {
      this.announcements = data;
      const ids = data.filter(item => !item.readAt).map(item => item.id);
      if (ids.length) {
        this.ans.markAnnouncementsAsRead(ids).subscribe(() => {
          this.ans.updateNumUnreadAnnouncements();
        });
      }
      this.loading = false;
    }, (error => {
      this.loading = false;
    }));
  }
}
