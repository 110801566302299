import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ConfirmSignUpComponent } from './auth/confirm-sign-up/confirm-sign-up.component';
import { ConfirmResetPasswordComponent } from './auth/confirm-reset-password/confirm-reset-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import {Amplify} from '@aws-amplify/core';
import {environment} from '../environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BaseComponent } from './base/base.component';
import {AppNotfoundComponent} from './base/notfound/app.notfound.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RequestReceivedComponent } from './auth/request-received/request-received.component';
import {AppTopBarComponent} from './base/topbar/app.topbar.component';
import {AppMenuComponent} from './base/menu/app.menu.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ChangePasswordComponent } from './account-settings/change-password/change-password.component';
import {AppMenuitemComponent} from './base/menu/app.menuitem.component';
import {PrimeModule} from './galaxy/prime.module';
import { IssuesComponent } from './issues/issues.component';
import { WeatherComponent } from './weather/weather.component';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import { PdfPagePlanComponent } from './pdfPage/plan/plan.component';
import {PdfPageScheduleComponent} from './pdfPage/schedule/schedule.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import {MomentModule} from 'ngx-moment';
import {LinkifyPipe} from './galaxy/linkify.pipe';
import {ChartsModule} from './charts/charts.module';
import { LevelAnalysisComponent } from './level-analysis/level-analysis.component';
import { ExportIssuesComponent } from './issues/export-issues/export-issues.component';
import { ModelViewComponent } from './model-view/model-view.component';
import { ViewerModule } from 'ng2-adsk-forge-viewer';
import { PriorityIssueComponent } from './issues/priority-issue/priority-issue.component';

Amplify.configure({
  Auth: {
    mandatorySignIn: environment.mandatorySignIn,
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    authenticationFlowType: environment.authenticationFlowType,
  }
});

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SignUpComponent,
    SignInComponent,
    ConfirmSignUpComponent,
    ConfirmResetPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    BaseComponent,
    AppNotfoundComponent,
    RequestReceivedComponent,
    AppTopBarComponent,
    AppMenuComponent,
    AccountSettingsComponent,
    ChangePasswordComponent,
    AppMenuitemComponent,
    IssuesComponent,
    WeatherComponent,
    PdfPagePlanComponent,
    PdfPageScheduleComponent,
    ContactsComponent,
    AnnouncementsComponent,
    LinkifyPipe,
    LevelAnalysisComponent,
    ExportIssuesComponent,
    ModelViewComponent,
    PriorityIssueComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PrimeModule,
    PdfJsViewerModule,
    MomentModule,
    ChartsModule,
    ViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
