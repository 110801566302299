import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AccountSettingsService} from '../account-settings.service';
import {Message} from 'primeng/api';
import {LabelPageService} from '../../base/topbar/app.label-page.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public loading = false;
  public title = 'Change Password';
  public messagesValue: Message[] = [];
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private profile: AccountSettingsService,
    private router: Router,
    private lps: LabelPageService,
  ) {
    this.lps.setData({label: this.title, backRouterLink: ['/account-settings']}, this.title);
    this.form = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]]
    });
  }

  async onSubmit(): Promise<void> {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.profile.changePassword(value.oldPassword, value.newPassword).subscribe(() => {
        this.loading = false;
        this.messagesValue = [{severity: 'success', summary: 'Success', detail: 'Updated password'}];
      }, error => {
        this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
        this.loading = false;
      });
    }
  }
}
