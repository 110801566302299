import { Injectable } from '@angular/core';
import {GHttpService} from '../galaxy/ghttp.service';
import {Observable, of} from 'rxjs';
import {delay, map, tap} from 'rxjs/operators';
import {AccessToken, ForgeModel, GQLAccessToken, GQLForgeModel, GQLLastVersionURNBase64} from './model-view';

@Injectable({
  providedIn: 'root'
})
export class ModelViewService {
  public EXPIRE_TIME_SERVER = 3599;
  private token: string|null = null;
  private models: ForgeModel[] = [];
  private expired = 0; // seconds

  constructor(
    private http: GHttpService
  ) { }

  private getCurrentSeconds(): number {
    return new Date().getTime() / 1000;
  }

  getAccessToken(): Observable<AccessToken> {
    if (this.token !== null && this.getCurrentSeconds() < this.expired) {
      return of({
        token: this.token,
        expireTime: this.expired - this.getCurrentSeconds()
      });
    }
    return this.http.graphql<string>(GQLAccessToken, ['user', 'forge', 'accessToken'])
      .pipe(
        map(data => {
          return {
            token: data,
            expireTime: this.EXPIRE_TIME_SERVER
          };
        }),
        tap(data => {
          this.token = data.token;
          this.expired = this.getCurrentSeconds() + this.EXPIRE_TIME_SERVER - 30;
        })
      );
  }
  getAllModels(): Observable<ForgeModel[]> {
    if (this.models.length > 0) {
      return of(this.models);
    }
    return this.http.graphqlGetFull<ForgeModel>(GQLForgeModel, {}, ['user', 'forge', 'models'])
      .pipe(
        map(data => {
          return data.map(item => {
            return {
              ...item,
              slug: item.title.toLowerCase().replace(/[ &]/gi, '-')
            };
          });
        }),
        tap(data => this.models = data)
      );
  }

  getLastVersionUrn(urn: string): Observable<string> {
    return this.http.graphql(GQLLastVersionURNBase64, ['user', 'forge', 'lastVersion'], {urn});
  }
}
