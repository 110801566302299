import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public loading = false;
  public title = 'Log In';
  public messagesValue: Message[] = [];
  public form: FormGroup = this.fb.group({
    email: ['', [Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Log in to Your Account | ' + environment.nameSite);
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.authService.signIn(value.email, value.password).subscribe(
        ((data) => {
          this.router.navigate([this.authService.redirectUrl]).then();
          this.loading = false;
        }), (error => {
          if (error.code === 'UserNotConfirmedException') {
            this.authService.confirmEmail = value.email;
            this.router.navigate(['confirm-sign-up']).then();
          }
          else if (error.code === 'UserLambdaValidationException') {
            this.messagesValue = [{severity: 'error', summary: 'Error', detail: 'Cannot authenticate'}];
          }
          else {
            this.messagesValue = [{severity: 'error', summary: 'Error', detail: error.message}];
          }
          this.loading = false;
        })
      );
    }
  }

}
