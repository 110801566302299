import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

export interface LabelPage {
  label: string;
  backRouterLink?: string[];
  selectPages?: {name: string, routerLink: string[]}[];
  activeRouterLink?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class LabelPageService {

  public labelPage = new Subject<LabelPage>();

  constructor(
    private titleService: Title
  ) {
  }

  setData(labelPage: LabelPage, title?: string): void {
    this.labelPage.next(labelPage);
    if (title) {
      this.titleService.setTitle(title + ' | ' + environment.nameSite);
    }
  }
}
