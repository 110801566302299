<div class="login-body">
  <div class="login-wrapper">
    <div class="login-panel">
      <a [routerLink]="['/sign-in']">
        <img [src]="'assets/images/logo.png'" alt="logo" />
      </a>
      <router-outlet></router-outlet>
      <p>Copyright © VIATechnik 2021</p>
    </div>
    <div class="login-image"></div>
  </div>
</div>
