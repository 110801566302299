import {PDFDocumentSegment} from '../files/files';

export const GQLScheduleFile = `
  query q {
    user {
      bimDocuments {
        coordinationScheduleList {
          edges {
            node {
              ${PDFDocumentSegment}
            }
          }
        }
      }
    }
  }
`;

export const GQLPlanFile = `
  query q {
    user {
      bimDocuments {
        executionPlanList {
          edges {
            node {
              ${PDFDocumentSegment}
            }
          }
        }
      }
    }
  }
`;

export interface PdfItem {
  url: string;
  name: string;
  filename: string;
  slug: string;
}
