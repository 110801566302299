import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/api';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public loading = false;
  public title = 'Request Access';
  public messagesValue: Message[] = [];
  public form: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    company: ['', [Validators.required]],
    email: ['', [Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.title + ' | ' + environment.nameSite);
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.loading = true;
    this.messagesValue = [];
    if (this.form.valid) {
      const value = this.form.value;
      this.authService.signUp(value.email, value.password, value.name, value.company).subscribe(
        (() => {
          this.authService.confirmEmail = value.email;
          this.router.navigate(['confirm-sign-up']).then();
          this.loading = false;
        }),
        ((error) => {
          this.messagesValue = [
            {
              severity: 'error',
              summary: 'Error',
              detail: error.message
            }
          ];
          this.loading = false;
        })
      );
    }
  }
}
