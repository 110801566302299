import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GHttpService} from '../galaxy/ghttp.service';
import {Contact, GQLAllContacts} from './contacts';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private http: GHttpService
  ) { }

  getContacts(): Observable<Contact[]> {
    return this.http.graphqlGetFull(GQLAllContacts, {}, ['user', 'contacts']);
  }
}
