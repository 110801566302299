<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h2 class="p-mb-6">{{title}}</h2>
  <p>
    We have sent a password reset code by email to {{email}}. Enter it below to reset your password.
  </p>
  <div class="p-fluid">
    <div class="p-field">
      <label>Code</label>
      <input pInputText formControlName="code" required autocomplete="one-time-code"/>
    </div>
    <div class="p-field">
      <label>New Password</label>
      <p-password [toggleMask]="true" formControlName="newPassword"></p-password>
    </div>
  </div>
  <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
  <button class="p-mt-3" pButton label="Change Password" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
</form>
