import {Component, OnDestroy} from '@angular/core';
import {BaseComponent} from '../base.component';
import {AuthService} from '../../auth/auth.service';
import {AnnouncementsService} from '../../announcements/announcements.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnDestroy {

  public model: any[] = [];
  private subscription: Subscription;

  constructor(
    public appBase: BaseComponent,
    private auth: AuthService,
    private as: AnnouncementsService
  ) {
    this.subscription = this.as.numUnreadAnnouncements.subscribe(unReadNumb => {
      this.auth.isAdmin().subscribe(
        (isAdmin) => {
          this.setMenu(isAdmin, unReadNumb);
        }
      );
      }
    );
  }

  setMenu(isAdmin: boolean, unReadNumb: number): void {
    this.model = [
      {
        label: '', items: [
          {label: 'Dashboard', icon: 'pi pi-chart-bar', routerLink: ['/dashboard']},
          {label: 'Level Analysis', icon: 'pi pi-chart-line', routerLink: ['/analysis']},
          {label: 'Model View', icon: 'pi pi-globe', routerLink: ['/model-view']},
          {label: 'Issue Log', icon: 'pi pi-book', routerLink: ['/issue-log']},
          {label: 'Announcements', icon: 'pi pi-bell', routerLink: ['/announcements'], badge: unReadNumb},
          {label: 'BIM Coordination Schedule', icon: 'pi pi-clock', routerLink: ['/bim-coordination-schedule']},
          {label: 'BIM Execution Plan', icon: 'pi pi-calendar', routerLink: ['/bim-execution-plan']},
          {label: 'Contacts', icon: 'pi pi-users', routerLink: ['/contacts']},
          {label: 'Admin Panel', icon: 'pi pi-cog', routerLink: ['/'], isHidden: !isAdmin,
            items: [
              {label: 'Users', routerLink: ['/admin', 'users']},
              {label: 'Schedule', routerLink: ['/admin', 'schedule']},
              {label: 'Misc Docs', routerLink: ['/admin', 'misc-docs']},
              {label: 'Announcements', routerLink: ['/admin', 'announcements']},
              // {label: 'Extra item', routerLink: ['/']},
            ]
          },

        ].filter(item => !item.isHidden)
      }
    ];
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
