export interface Announcement {
  id: string;
  subject: string;
  text: string;
  author: {name: string };
  createdAt: string;
  readAt: string;
}

const announcementSegment = `
  id
  subject
  text
  author {name}
  createdAt
  readAt
`;

export const GQLAllAnnouncements = `
  query q($after: String) {
    user {
      announcements {
        list(after: $after) {
          edges {
            node {
              ${announcementSegment}
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;
export const GQLNumUnreadAnnouncements = `
  query q {
    user {
      announcements {
        numberOfUnread
      }
    }
  }
`;

export function GQLMarkAnnouncementsAsRead(ids: string[]): string {
  const qi = ids.map((id, index) => {
    return `
      announcement${index}: announcements(id: "${id}") {
          read {
            success
          }
        }
    `;
  }).join();
  return  `
    mutation m {
      user {
        ${qi}
      }
    }
  `;
}
