import { Injectable } from '@angular/core';
import {Auth} from '@aws-amplify/auth';
import {fromPromise} from 'rxjs/internal-compatibility';
import {AuthService} from '../auth/auth.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {CognitoUserAttributes} from '../auth/auth';
import {Observable} from 'rxjs';
import {CreateFileMutationInput, CreateUploadedFileMutationInput, CreateUploadedFileMutationPayload} from '../files/files';
import {GHttpService} from '../galaxy/ghttp.service';
import {GQLChangeAvatar, GQLDeleteAvatar, GQLUploadFile} from './account-settings';
import {GQLSuccessResult} from '../galaxy/g';
import {FilesService} from '../files/files.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {

  constructor(
    private auth: AuthService,
    private http: GHttpService,
    private fs: FilesService
  ) {
  }

  updateProfile(attributes: CognitoUserAttributes): Observable<string> {
    return this.auth.cognitoUser().pipe(
      switchMap(cognitoUser => {
        return fromPromise(Auth.updateUserAttributes(cognitoUser, attributes)).pipe(
          tap(() => this.auth.cognitoUserToCurrentUser())
        );
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<string> {
    return this.auth.cognitoUser().pipe(
      switchMap(cognitoUser => {
        return fromPromise(Auth.changePassword(cognitoUser, oldPassword, newPassword));
      })
    );
  }

  private uploadFile(file: File): Observable<CreateUploadedFileMutationPayload> {
    const input: CreateUploadedFileMutationInput = {
      filename: file.name,
      contentType: file.type
    };
    return fromPromise(this.fs.resizeImage({file, maxSize: 200})).pipe(
      switchMap(blobFile => {
        return this.http.graphql<CreateUploadedFileMutationPayload>(GQLUploadFile, ['user', 'profile', 'uploadFile'], {input})
          .pipe(
            switchMap(result => {
              return this.http.uploadFileS3(blobFile, JSON.parse(atob(result.credentials))).pipe(
                map(() => result)
              );
            })
          );
      })
    );
  }

  changeAvatar(file: File): Observable<GQLSuccessResult> {
    return this.uploadFile(file).pipe(
      switchMap((result) => {
        const input: CreateFileMutationInput = {
          uploadedFile: result.uploadedFile.id
        };
        return this.http.graphql<GQLSuccessResult>(
          GQLChangeAvatar, ['user', 'profile', 'changeAvatar'], {input});
      })
    );
  }

  deleteAvatar(): Observable<GQLSuccessResult> {
    return this.http.graphql<GQLSuccessResult>(GQLDeleteAvatar, ['user', 'profile', 'deleteAvatar']);
  }

}
