<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h2 class="p-mb-6">{{title}}</h2>

  <div class="p-fluid">
    <div class="p-field">
      <label>Email Address</label>
      <input pInputText formControlName="email" autocomplete="email"/>
    </div>
    <div class="p-field">
      <label>Password</label>
      <p-password [toggleMask]="true" [feedback]="false" formControlName="password" autocomplete="current-password"></p-password>
    </div>
  </div>
  <p-messages [(value)]="messagesValue" [enableService]="false"></p-messages>
  <button class="p-mt-3" pButton label="Log in" type="submit" [disabled]="!form.valid" [loading]="loading"></button>
  <br>
  <p><a [routerLink]="['/reset-password']">Forgot password?</a></p>
  <p>Don't have an account? <a [routerLink]="['/sign-up']">Request Access</a></p>

</form>

