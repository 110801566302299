<p-card>
  <p-table #dt [value]="issues" [lazy]="true" (onLazyLoad)="filter($event, dt)"
           [paginator]="!!issues.length" [rows]="pageSize" [rowsPerPageOptions]="pageSizeOptions" [showCurrentPageReport]="true"
           [totalRecords]="totalCount" [loading]="loading"
           [globalFilterFields]="['name']" [columns]="selectedColumns"
           stateStorage="session" stateKey="issues"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-mr-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" #gf (input)="dt.filterGlobal(gf.value, 'contains')"
                 [value]="$any(dt).filters['global']?.value"
                 placeholder="Search" />
        </span>
        <p-multiSelect
          class="p-ml-2"
          [options]="columns"
          [(ngModel)]="selectedColumns"
          (onChange)="clearTable(dt, gf)"
          optionLabel="header"
          optionDisabled="inactive"
          selectedItemsLabel="Columns"
          [showHeader]="false"
          [style]="{minWidth: '200px'}">
        </p-multiSelect>
        <app-export-issues class="p-ml-2" label="Export"></app-export-issues>
        <button pButton label="Clear" class="p-button-outlined p-ml-2" icon="pi pi-filter-slash" (click)="clearTable(dt, gf)"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-sColumns>
      <tr>
        <th *ngFor="let col of sColumns" [pSortableColumn]="col.field">
          <div class="p-d-flex p-jc-between p-ai-center">
            {{col.header}}
            <p-sortIcon *ngIf="!fieldsHiddenSort.includes(col.field)" [field]="col.field"></p-sortIcon>
            <div *ngIf="filterFieldsVariantSelect.includes(col.field)">
              <p-columnFilter [field]="col.field" matchMode="equals" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [ngModel]="value"
                              [options]="filtersVariants[col.suggestionsName || col.field]"
                              (onChange)="filter($event.value)"
                              placeholder="Any"
                              [showClear]="true"
                              optionLabel="value"
                              [optionValue]="filtersVariants[col.suggestionsName || col.field][0]?.id ? 'id' : 'value'"
                  ></p-dropdown>
                </ng-template>
              </p-columnFilter>
            </div>
            <div *ngIf="filterFieldsVariantDate.includes(col.field)">
              <p-columnFilter [field]="col.field" matchMode="equals" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" display="menu">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-calendar [ngModel]="value" (onSelect)="filter($event)"></p-calendar>
                </ng-template>
              </p-columnFilter>
            </div>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div [ngSwitch]="col.field">
            <div *ngSwitchCase="'status'"> <b>{{item.status}}</b></div>
            <div *ngSwitchCase="'zone'">{{item.zone.name}}</div>
            <div *ngSwitchCase="'dueDate'">{{item.dueDate|date:"shortDate"}}</div>
            <div *ngSwitchCase="'closingDate'">{{item.closingDate|date:"shortDate"}}</div>
            <div *ngSwitchCase="'issueCreated'">{{item.issueCreated|date:"shortDate"}}</div>
            <div *ngSwitchCase="'lastModificationDate'">{{item.lastModificationDate|date:"shortDate"}}</div>
            <div *ngSwitchCase="'disciplines'">{{item.disciplines.join(', ')}}</div>
            <div *ngSwitchCase="'uniformats'">{{item.uniformats.join(', ')}}</div>
            <div *ngSwitchCase="'teams'">{{item.teams.join(', ')}}</div>
            <div *ngSwitchCase="'priority'"><app-priority-issue [name]="item.priority"></app-priority-issue></div>

            <div *ngSwitchDefault>
              {{item[col.field]}}
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No issues found.</td>
      </tr>
    </ng-template>
  </p-table>

</p-card>

