import { Injectable } from '@angular/core';
import {GHttpService} from '../galaxy/ghttp.service';
import {Observable} from 'rxjs';
import {DashboardNamespace, GQLDashboardNamespace, GQLDashboardOverallBimModelHealth, HealthPoint} from './dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private http: GHttpService
  ) { }

  getData(): Observable<DashboardNamespace> {
    return this.http.graphql(GQLDashboardNamespace, ['user', 'dashboard']);
  }

  getOverallBimModelHealth(period: string, startDate: string, endDate: string): Observable<HealthPoint[]> {
    return this.http.graphql(GQLDashboardOverallBimModelHealth, ['user', 'dashboard', 'charts', 'overallBimModelHealth'], {
      period, startDate, endDate
    });
  }
}
