
export const GQLUploadFile = `
  mutation m($input: UploadFileMutationInput!) {
    user {
      profile {
        uploadFile(input: $input) {
          credentials
          uploadedFile {id}
        }
      }
    }
  }
`;


export const GQLChangeAvatar = `
  mutation m($input: ChangeAvatarMutationInput!) {
    user {
      profile {
        changeAvatar(input: $input) {
          success
        }
      }
    }
  }
`;

export const GQLDeleteAvatar = `
  mutation m {
    user {
      profile {
        deleteAvatar {
          success
        }
      }
    }
  }
`;
