export const GQLWeather = `
  query q {
    user {
      weather {
        place
        cloudiness
        temperature
        icon
      }
    }
  }
`;

export interface GalaxyWeather {
  temperature: number;
  icon: string;
  cloudiness: number;
  place: string;
}
