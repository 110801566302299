import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated().pipe(
      switchMap(isAuth => {
        if (isAuth) {
          return this.auth.isApproved().pipe(map(isApproved => {
            if (isApproved) {
              return true;
            }
            else {
              this.auth.signOut(false);
              return this.router.parseUrl('/request-received');
            }
          }));
        }
        else {
          this.auth.redirectUrl = url;
          return of(this.router.parseUrl('/sign-in'));
        }
      })
    );
  }
}
